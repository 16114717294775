import {
  SET_ACTIVATE_MESSAGE,
  SET_ACTIVE,
  SET_LOADING,
  SET_SUBMITING,
  SET_USER,
  SIGN_FAILURE,
  SIGN_OUT_SUCCESS,
  UPDATE_USER,
  UserDispatch,
} from "./misc";
import { User } from "../../domain/user/models/User";
import { EnqueueSnackbar } from "../../domain/core/utils";
import { Company } from "../../domain/company/models/Company";
import { IUserRepository } from "../../domain/user/iuser.repository";

export default class UserActions {
  private userRepository: IUserRepository;

  constructor(userRepository: IUserRepository) {
    this.userRepository = userRepository;
  }

  public async sendEmail(
    // dispatch: UserDispatch,
    email: string,
    name: string,
    enqueueSnackbar: EnqueueSnackbar
  ) {
    const result = await this.userRepository.sendValidateEmail(email, name);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      // dispatch({ type: SET_ACTIVATE_MESSAGE, payload: "Something went wrong" });
      return;
    }
    enqueueSnackbar("Email send successfully", { variant: "success" });
  }

  public async resetPassword(
    dispatch: UserDispatch,
    token: string,
    password: string,
    history: any,
    enqueueSnackbar: EnqueueSnackbar
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.userRepository.resetPassword(token, password);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      dispatch({ type: SET_SUBMITING, payload: false });
      return;
    }
    enqueueSnackbar("Your password has been changed.", {
      variant: "success",
    });
    dispatch({ type: SET_SUBMITING, payload: false });
    history.replace("/");
  }

  public async sendResetPasswordEmail(
    dispatch: UserDispatch,
    email: string,
    enqueueSnackbar: EnqueueSnackbar
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.userRepository.sendResetPasswordEmail(email);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      dispatch({ type: SET_SUBMITING, payload: false });
      return;
    }
    enqueueSnackbar(
      "We send you a password reset email. Check your inbox and follow the intructions",
      {
        variant: "success",
      }
    );
    dispatch({ type: SET_SUBMITING, payload: false });
  }

  public async activateUser(
    dispatch: UserDispatch,
    token: string,
    history: any,
    enqueueSnackbar: EnqueueSnackbar
  ) {
    dispatch({ type: SET_ACTIVATE_MESSAGE, payload: "Activating..." });
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.userRepository.activateUser(token);
    if (result.isLeft) {
      dispatch({ type: SET_ACTIVATE_MESSAGE, payload: "Something went wrong" });
      dispatch({ type: SET_SUBMITING, payload: false });
      return;
    }
    dispatch({ type: SET_ACTIVATE_MESSAGE, payload: "Account Activated" });
    enqueueSnackbar("Account activated", { variant: "success" });
    dispatch({ type: SET_ACTIVE, payload: true });
    dispatch({ type: SET_SUBMITING, payload: false });
    history.replace("/");
  }

  public async updateUser(
    dispatch: UserDispatch,
    user: User,
    enqueueSnackbar: EnqueueSnackbar
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.userRepository.update(user);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      dispatch({ type: SET_SUBMITING, payload: false });
      return;
    }
    dispatch({ type: UPDATE_USER, payload: user });
    dispatch({ type: SET_SUBMITING, payload: false });
  }

  public async checkAuthenticated(dispatch: any) {
    dispatch({ type: SET_LOADING, payload: true });
    const result = await this.userRepository.getAuthenticatedUser();
    if (result.isLeft) {
      dispatch({ type: SIGN_FAILURE });
      return;
    }
    const user: User = result.getValue();
    dispatch({ type: SET_USER, payload: user });
    dispatch({ type: SET_LOADING, payload: false });
  }

  public async signIn(
    dispatch: UserDispatch,
    email: string,
    password: string,
    history: any,
    enqueueSnackbar: EnqueueSnackbar
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.userRepository.login(email, password);
    console.log(`result`, result);
    if (result.isLeft) {
      dispatch({ type: SIGN_FAILURE });
      dispatch({ type: SET_SUBMITING, payload: false });
      enqueueSnackbar(result.getError(), { variant: "error" });
      return;
    }
    const user: User = result.getValue();
    history.replace("/app/dashboard");
    dispatch({ type: SET_USER, payload: user });
  }

  public async signUp(
    dispatch: UserDispatch,
    name: string,
    email: string,
    password: string,
    company: Company,
    history: any,
    enqueueSnackbar: EnqueueSnackbar
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.userRepository.register(
      name,
      email,
      password,
      company
    );
    if (result.isLeft) {
      dispatch({ type: SIGN_FAILURE });
      dispatch({ type: SET_SUBMITING, payload: false });
      enqueueSnackbar(result.getError(), { variant: "error" });
      return;
    }
    const user: User = result.getValue();
    history.replace("/app/dashboard");
    dispatch({ type: SET_USER, payload: user });
  }

  public async signOut(dispatch: UserDispatch, history: any) {
    this.userRepository.signOut();
    dispatch({ type: SIGN_OUT_SUCCESS });
    history.replace("/");
  }
}
