import { AccountCircle, Home, People, Settings } from "@material-ui/icons";
import Dashboard from "../pages/Dashboard";
import Contractors from "../pages/Contractors";
import Profile from "../pages/Profile";
import SettingsPage from "../pages/Settings";

export const ownerRoutes = [
  {
    path: "/dashboard",
    name: "Business Dashboard",
    icon: Home,
    component: Dashboard,
    layout: "/app",
    onSidebar: true,
    onNavbar: false,
  },
  {
    path: "/contractors",
    name: "Contractors",
    icon: People,
    component: Contractors,
    layout: "/app",
    onSidebar: true,
    onNavbar: false,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: AccountCircle,
    component: Profile,
    layout: "/app",
    onSidebar: false,
    onNavbar: true,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: Settings,
    component: SettingsPage,
    layout: "/app",
    onSidebar: false,
    onNavbar: true,
  },
];

export const contractorRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Home,
    component: Dashboard,
    layout: "/app",
    onSidebar: true,
    onNavbar: false,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: AccountCircle,
    component: Profile,
    layout: "/app",
    onNavbar: true,
    onSidebar: true,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: Settings,
    component: SettingsPage,
    layout: "/app",
    onSidebar: false,
    onNavbar: true,
  },
];
