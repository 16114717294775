import { Document } from "../../../domain/document";

export interface DocumentDtoProps {
  id?: string;
  title: string;
  filename: string;
  category: string;
  requirement_met?: boolean;
  url?: string;
  expire_on: Date;
  issued_on: Date;
}

export default class DocumentDto {
  id?: string;
  title: string;
  filename: string;
  category: string;
  requirement_met?: boolean;
  url?: string;
  expire_on: Date;
  issued_on: Date;

  constructor(props: DocumentDtoProps) {
    this.id = props.id;
    this.title = props.title;
    this.filename = props.filename;
    this.category = props.category;
    this.requirement_met = props.requirement_met;
    this.url = props.url;
    this.expire_on = props.expire_on;
    this.issued_on = props.issued_on;
  }

  public static fromDomain(document: Document): DocumentDto {
    return new DocumentDto({
      id: document.id,
      title: document.title,
      filename: document.filename,
      category: document.category,
      requirement_met: document.requirement_met,
      url: document.url,
      expire_on: document.expire_on,
      issued_on: document.issued_on,
    });
  }

  public toDomain(): Document {
    return new Document({
      id: this.id,
      title: this.title,
      filename: this.filename,
      category: this.category,
      url: this.url,
      expire_on: new Date(this.expire_on),
      issued_on: new Date(this.issued_on),
      requirement_met: this.requirement_met ?? false,
    });
  }
}
