import classNames from "classnames";
import React from "react";
import useStyles from "./styles";

export default function Legend() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classNames(classes.item, classes.met)}>Met</div>
      <div className={classNames(classes.item, classes.expire_soon)}>
        Expire soon
      </div>
      <div className={classNames(classes.item, classes.expired)}>Expired</div>
    </div>
  );
}
