import { User } from "../../../domain/user/models/User";
import { CompanyDto } from "../../company/dtos/CompanyDto";
import { ContractorDto } from "../../contractor/dtos/ContractorDto";

export interface IUserProps {
  id?: string;
  role?: string;
  name: string;
  email: string;
  password?: string;
  phone?: string;
  company?: CompanyDto;
  contractorProps?: ContractorDto;
  active?: boolean;
}

export class UserDto {
  id?: string;
  role?: string;
  name: string;
  email: string;
  password?: string;
  phone?: string;
  company?: CompanyDto;
  contractorProps?: ContractorDto;
  active?: boolean;

  constructor(props: IUserProps) {
    this.id = props.id;
    this.name = props.name;
    this.role = props.role;
    this.email = props.email;
    this.password = props.password;
    this.phone = props.phone;
    this.active = props.active;
    this.company = !!props.company ? new CompanyDto(props.company) : undefined;
    this.contractorProps = !!props.contractorProps
      ? new ContractorDto(props.contractorProps)
      : undefined;
  }

  public static fromDomain(user: User): UserDto {
    return new UserDto({
      id: user.id,
      name: user.name,
      role: user.role,
      email: user.email,
      password: user.password,
      phone: user.phone,
      active: user.active,
      company: !!user.company ? CompanyDto.fromDomain(user.company) : undefined,
      contractorProps: !!user.contractorProps
        ? ContractorDto.fromDomain(user.contractorProps)
        : undefined,
    });
  }

  public toDomain(): User {
    return new User({
      id: this.id,
      name: this.name,
      role: this.role,
      email: this.email,
      active: this.active,
      phone: this.phone,
      company: this.company?.toDomain(),
      contractorProps: this.contractorProps?.toDomain(),
    });
  }
}
