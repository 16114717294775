import { Typography } from "@material-ui/core";
import React from "react";
import { Document } from "../../../../../../../../domain/document";
import { DocumentsTable } from "../../../../../../../core/components/Table";
import TabHeader from "../../components/TabHeader";

type TableTabProps = {
  title: string;
  data: Document[];
};

export default function TableTab(props: TableTabProps) {
  const { title, data } = props;
  return (
    <div>
      <TabHeader title={title} />
      {data.length !== 0 ? (
        <DocumentsTable data={data} />
      ) : (
        <Typography>There are no documents yet</Typography>
      )}
    </div>
  );
}
