import { CONTRACTORS_ENDPOINT, Headers } from "../../../core/tools/env";
import { handleResponse } from "../../core/helpers";
import { DocumentDto } from "../../document";
import { UserDto } from "../../user/dtos/UserDto";

export interface IContractorRemoteDatasource {
  getAllByCompanyId(token: any, companyId: string): Promise<UserDto[]>;
  create(token: any, contractor: UserDto): Promise<UserDto>;
  update(token: string, user: UserDto): Promise<void>;
  delete(token: string, id: string): Promise<void>;
  uploadDocument(token: string, data: FormData): Promise<DocumentDto[]>;
}

export default class ContractorRemoteDatasource
  implements IContractorRemoteDatasource
{
  async uploadDocument(token: string, data: FormData): Promise<DocumentDto[]> {
    const response = await fetch(CONTRACTORS_ENDPOINT + "/uploadDocument", {
      method: "post",
      headers: { Authorization: "Bearer " + token },
      body: data,
    });
    console.log(`response`, response);
    const responseData = await handleResponse(response);
    return responseData.map((item: any) => new DocumentDto({ ...item }));
    // if (!response.ok) throw data;
  }

  async getAllByCompanyId(token: any, companyId: string): Promise<UserDto[]> {
    const response = await fetch(
      CONTRACTORS_ENDPOINT + "/getByCompanyId/" + companyId,
      {
        method: "get",
        headers: Headers(token),
      }
    );
    const data = await handleResponse(response);
    // if (!response.ok) throw data;
    return data.map((item: any) => new UserDto({ ...item }));
  }

  async create(token: any, contractor: UserDto): Promise<UserDto> {
    const body = JSON.stringify(contractor);
    const response = await fetch(CONTRACTORS_ENDPOINT + "/register", {
      method: "post",
      headers: Headers(token),
      body: body,
    });
    const data = await handleResponse(response);
    // if (!response.ok) throw data;
    return new UserDto(data);
  }

  async update(token: string, user: UserDto): Promise<void> {
    const body = JSON.stringify(user);
    const response = await fetch(CONTRACTORS_ENDPOINT + "/update", {
      method: "put",
      headers: Headers(token),
      body: body,
    });
    await handleResponse(response);
    // if (!response.ok) {
    //   const data = await response.json();
    //   throw data;
    // }
  }

  async delete(token: string, id: string): Promise<void> {
    const response = await fetch(CONTRACTORS_ENDPOINT + "/delete/" + id, {
      method: "delete",
      headers: Headers(token),
      // body: body,
    });
    await handleResponse(response);
    // if (!response.ok) {
    //   const data = await response.json();
    //   throw data;
    // }
  }
  // async login(email: string, password: string): Promise<SignDto> {
  //   const response = await fetch(AUTH_ENDPOINT + "/login", {
  //     method: "post",
  //     headers: Headers(),
  //     body: JSON.stringify({
  //       email: email,
  //       password: password,
  //     }),
  //   });
  //   const data: SignDto = await response.json();
  //   if (!response.ok) throw data;
  //   return data;
  // }
  // async register(
  //   name: string,
  //   email: string,
  //   password: string,
  //   company: CompanyDto
  // ): Promise<SignDto> {
  //   const response = await fetch(AUTH_ENDPOINT + "/register", {
  //     method: "post",
  //     headers: Headers(),
  //     body: JSON.stringify({
  //       email: email,
  //       password: password,
  //       name: name,
  //       company: company,
  //     }),
  //   });
  //   const data: SignDto = await response.json();
  //   if (!response.ok) throw data;
  //   return data;
  // }
  // async update(token: string, user: UserDto): Promise<void> {
  //   const response = await fetch(AUTH_ENDPOINT + "/updateProfile", {
  //     method: "put",
  //     headers: Headers(token),
  //     body: JSON.stringify(user),
  //   });
  //   console.log(`response`, response);
  //   const data = await response.json();
  //   console.log(`data`, data);
  //   if (!response.ok) throw data;
  //   return data;
  // }
  // async updateContractor(token: string, user: UserDto): Promise<void> {
  //   const body = JSON.stringify(user);
  //   const response = await fetch(CONTRACTORS_ENDPOINT + "/update", {
  //     method: "put",
  //     headers: Headers(token),
  //     body: body,
  //   });
  //   console.log(`response`, response);
  //   // console.log(`data`, data);
  //   if (!response.ok) {
  //     const data = await response.json();
  //     throw data;
  //   }
  // }
  // async getAuthenticatedUser(token: string): Promise<UserDto> {
  //   const response = await fetch(AUTH_ENDPOINT + "/profile", {
  //     method: "get",
  //     headers: Headers(token),
  //   });
  //   const data = await response.json();
  //   console.log(`getAuthenticatedUser data`, data);
  //   if (!response.ok) throw data;
  //   return new UserDto(data.user);
  // }
}
