import React, { useEffect, useState } from "react";
import {
  makeStyles,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Input,
  MenuItem,
} from "@material-ui/core";
import WrappedButton from "../../../../../core/components/Button";
import { useContractorState } from "../../../../../../application/contractor";
// import Proptypes from "prop-types";
import { useUserState } from "../../../../../../application/user";
import { useSnackbar } from "notistack";
import { User } from "../../../../../../domain/user/models/User";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 10px",
    maxWidth: "30vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90vw",
    },
  },
  content: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  textField: {
    marginTop: "15px",
  },
  buttonContainer: {
    marginTop: "30px",
    // width: "100%",
    // width: "80%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    width: "100%",
  },
}));

const selectLanguages = ["English", "Spanish"];

export default function EditContractorModalContent(props: any) {
  const classes = useStyles();

  const { isEditing, onSuccessCallback } = props;

  const { user } = useUserState();

  const { selectedContractor } = useContractorState();

  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState("Lolo");
  const [email, setEmail] = useState("lolo@gmail.com");
  const [phone, setPhone] = useState("53535353");
  const [password, setPassword] = useState("123456Lo");
  const [address, setAddress] = useState("Ave 34");
  const [providerId, setProviderId] = useState("laskjf2oifj2");
  const [licenceNumber, setLicenceNumber] = useState("j2flk4f2l");
  const [profession, setProfession] = useState("RBT");
  const [languages, setLanguages] = useState(["Spanish"]);

  const handleChange = (event: any) => {
    setLanguages(event.target.value);
  };

  useEffect(() => {
    if (isEditing && selectedContractor) {
      setName(selectedContractor?.name ?? "");
      setEmail(selectedContractor?.email ?? "");
      setPhone(selectedContractor?.phone ?? "");
      setAddress(selectedContractor?.contractorProps?.address ?? "");
      setProviderId(selectedContractor?.contractorProps?.providerId ?? "");
      setLicenceNumber(
        selectedContractor?.contractorProps?.licenseNumber ?? ""
      );
      setLanguages(selectedContractor?.contractorProps?.language ?? []);
      setProfession(selectedContractor?.contractorProps?.profession ?? "");
    }
  }, [isEditing, selectedContractor]);

  const validateForm = () => {
    const validEmailAndName = name !== "" && email !== "";
    const finalValidation = isEditing
      ? validEmailAndName
      : validEmailAndName && password !== "";
    return finalValidation;
  };

  const getContractor = () => {
    return isEditing
      ? new User({
          id: selectedContractor?.id,
          name: name,
          email: email,
          phone: phone,
          contractorProps: {
            id: selectedContractor?.contractorProps?.id,
            profession: profession,
            address: address,
            providerId: providerId,
            licenseNumber: licenceNumber,
            language: languages,
          },
        })
      : new User({
          name: name,
          email: email,
          password: password,
          phone: phone,
          company: {
            id: user?.company?.id,
          },
          contractorProps: {
            profession: profession,
            address: address,
            providerId: providerId,
            licenseNumber: licenceNumber,
            language: languages,
          },
        });
  };

  const onButtonClick = () => {
    const isValidForm = validateForm();
    if (isValidForm) {
      onSuccessCallback(getContractor());
    } else {
      enqueueSnackbar("Form invalid", { variant: "error" });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h3">
          {isEditing ? "Edit Contractor" : "Add Contractor"}
        </Typography>
        {/* <div className={classes.inputsContainer}> */}
        <TextField
          className={classes.textField}
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!isEditing && (
          <TextField
            className={classes.textField}
            fullWidth
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}
        <TextField
          className={classes.textField}
          fullWidth
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label="Profession"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label="Provider ID"
          value={providerId}
          onChange={(e) => setProviderId(e.target.value)}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label="Licence Number"
          value={licenceNumber}
          onChange={(e) => setLicenceNumber(e.target.value)}
        />
        <FormControl fullWidth className={classes.textField}>
          <InputLabel id="demo-mutiple-chip-label">Languages</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={languages}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected: any) => (
              <div className={classes.chips}>
                {selected.map((value: any) => (
                  <Chip key={value} label={value} className={classes.chips} />
                ))}
              </div>
            )}
            // MenuProps={MenuProps}
          >
            {selectLanguages.map((name) => (
              <MenuItem
                key={name}
                value={name}
                // style={getStyles(name, personName, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* </div> */}
        <div className={classes.buttonContainer}>
          <WrappedButton className={classes.button} onClick={onButtonClick}>
            {isEditing ? "Edit" : "Add"}
          </WrappedButton>
        </div>
      </div>
    </div>
  );
}

// EditContractorModalContent.propTypes = {
//   isEditing: Proptypes.bool,
//   onSuccessCallback: Proptypes.func.isRequired,
// };
