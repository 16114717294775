export const handleResponse = async (response: Response) => {
  console.log(`response`, response);
  let data;
  try {
    data = await response.json();
    console.log(`data`, data);
  } catch (error) {
    console.log(`error on response.json()`, error);
    if (!response.ok) throw new Error(response.statusText);
    return { message: response.statusText };
  }
  if (!response.ok) throw data;
  return data || { message: response.statusText };
};
