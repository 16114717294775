import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import useStyles from "./styles";
import { useUserState } from "../../application/user";
import { getRoutes } from "../../core/tools/helpers";
import ErrorPage from "../Error";

export default function App() {
  const classes = useStyles();

  const { user } = useUserState();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const routes = getRoutes(user?.role);

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      })}
      <Route path="*" component={ErrorPage} />
    </Switch>
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar open={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <div className={classes.mainPanel}>
        <Navbar handleDrawerToggle={handleDrawerToggle} />
        <div className={classes.content}>{switchRoutes}</div>
      </div>
    </div>
  );
}
