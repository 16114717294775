import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  card: {
    marginTop: "30px",
    width: "30vw",
    minHeight: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },
}));
