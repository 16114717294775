import { IContractorRepository } from "../../domain/contractor/icontractor.repository";
import { Result } from "../../domain/core/Result";
import { User } from "../../domain/user/models/User";
import Document from "../../domain/document/models/Document";
import { UserDto } from "../user/dtos/UserDto";
import { IContractorLocalDatasource } from "./datasources/local";
import { IContractorRemoteDatasource } from "./datasources/remote";

export default class ContractorRepository implements IContractorRepository {
  private remoteDatasource: IContractorRemoteDatasource;
  private localDatasource: IContractorLocalDatasource;

  constructor(
    remoteDatasource: IContractorRemoteDatasource,
    localDatasource: IContractorLocalDatasource
  ) {
    this.remoteDatasource = remoteDatasource;
    this.localDatasource = localDatasource;
  }

  async uploadDocument(data: FormData): Promise<Result<Document[]>> {
    try {
      const token = this.localDatasource.getAccessToken();
      if (!token) return Result.left("Unauthenticated");
      const documentDtos = await this.remoteDatasource.uploadDocument(
        token,
        data
      );
      const documents = documentDtos.map((item) => item.toDomain());
      console.log(`documents`, documents);
      return Result.rigth(documents);
    } catch (error) {
      return Result.left(error.message);
    }
  }

  async getAllByCompanyId(companyId: string): Promise<Result<User[]>> {
    try {
      const token = this.localDatasource.getAccessToken();
      const userDtos = await this.remoteDatasource.getAllByCompanyId(
        token,
        companyId
      );
      const users = userDtos.map((item: UserDto, index: number) =>
        item.toDomain()
      );
      return Result.rigth(users);
    } catch (error) {
      console.log(`error`, error);
      return Result.left(error.message);
    }
  }
  async create(contractor: User): Promise<Result<User>> {
    try {
      const token = this.localDatasource.getAccessToken();
      if (!token) return Result.left("Unauthenticated");
      const contractorDto = await this.remoteDatasource.create(
        token,
        UserDto.fromDomain(contractor)
      );
      return Result.rigth(contractorDto.toDomain());
    } catch (error) {
      return Result.left(error.message);
    }
  }
  async update(user: User): Promise<Result<void>> {
    try {
      const token = this.localDatasource.getAccessToken();
      if (!token) return Result.left("Unauthenticated");
      await this.remoteDatasource.update(token, UserDto.fromDomain(user));
      return Result.rigth("Contractor Updated");
    } catch (error) {
      return Result.left(error.message);
    }
  }
  async delete(id: string): Promise<Result<void>> {
    try {
      const token = this.localDatasource.getAccessToken();
      if (!token) return Result.left("Unauthenticated");
      await this.remoteDatasource.delete(token, id);
      return Result.rigth("Contractor Deleted");
    } catch (error) {
      return Result.left(error.message);
    }
  }
}
