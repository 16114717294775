import React, { useState } from "react";
import { Paper, Tab, Tabs } from "@material-ui/core";
import useStyles from "./styles";
import GeneralInformation from "./tabs/GeneralInformation";
import TableTab from "./tabs/TableTab";
import { Categories } from "../../../../../../core/tools/constants";
import { useContractorState } from "../../../../../../application/contractor";

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "20px" }}>{children}</div>}
    </div>
  );
};

export default function InfoTabs() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const { selectedContractor } = useContractorState();

  const documents = selectedContractor.contractorProps?.documents ?? [];

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="disabled tabs example"
      >
        <Tab label="General Information" />
        <Tab label={Categories.personalInformation} />
        <Tab label={Categories.legalDocumentation} />
        <Tab label={Categories.educationalLevel} />
        <Tab label={Categories.trainingCertificates} />
        <Tab label={Categories.firstAid} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <GeneralInformation />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableTab
          title={Categories.personalInformation}
          data={documents.filter(
            (item) => item.category === Categories.personalInformation
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableTab
          title={Categories.legalDocumentation}
          data={documents.filter(
            (item) => item.category === Categories.legalDocumentation
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableTab
          title={Categories.educationalLevel}
          data={documents.filter(
            (item) => item.category === Categories.educationalLevel
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TableTab
          title={Categories.trainingCertificates}
          data={documents.filter(
            (item) => item.category === Categories.trainingCertificates
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TableTab
          title={Categories.firstAid}
          data={documents.filter(
            (item) => item.category === Categories.firstAid
          )}
        />
      </TabPanel>
    </Paper>
  );
}
