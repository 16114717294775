import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ContractorsPage from "./pages/ContractorsPage";
import ContractorDetails from "./pages/ContractorDetails";
import {
  // fetchContractors,
  useContractorDispatch,
} from "../../../../application/contractor";
import { useUserState } from "../../../../application/user";
import { useSnackbar } from "notistack";
import { useInjection } from "../../../../core/dependency_injection/ioc.react";
import ContractorActions from "../../../../application/contractor/actions";

export default function Contractors() {
  const { user } = useUserState();

  // console.log(`user`, user);

  const contractorDispatch = useContractorDispatch();

  const contractorActions =
    useInjection<ContractorActions>("ContractorActions");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    contractorActions.fetchContractors(
      contractorDispatch,
      user?.company?.id ?? "",
      enqueueSnackbar
    );
  }, [
    contractorDispatch,
    user?.company?.id,
    enqueueSnackbar,
    contractorActions,
  ]);

  return (
    <div>
      <Switch>
        <Route path="/app/contractors/:id" component={ContractorDetails} />
        <Route path="/app/contractors" component={ContractorsPage} />
      </Switch>
    </div>
  );
}
