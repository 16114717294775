import React from "react";
import { ContractorDispatch, initialValues } from "./misc";
import reducer from "./reducer";

var ContractorStateContext = React.createContext(initialValues);
var ContractorDispatchContext = React.createContext<ContractorDispatch>(
  () => null
);

function ContractorProvider({ children }: any) {
  var [state, dispatch] = React.useReducer(reducer, initialValues);
  return (
    <ContractorStateContext.Provider value={state}>
      <ContractorDispatchContext.Provider value={dispatch}>
        {children}
      </ContractorDispatchContext.Provider>
    </ContractorStateContext.Provider>
  );
}

function useContractorState() {
  var context = React.useContext(ContractorStateContext);
  if (context === undefined) {
    throw new Error(
      "useContractorState must be used within a ContractorProvider"
    );
  }
  return context;
}

function useContractorDispatch() {
  var context = React.useContext(ContractorDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useContractorDispatch must be used within a ContractorProvider"
    );
  }
  return context;
}

export { ContractorProvider, useContractorState, useContractorDispatch };
