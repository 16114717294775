import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
// import Proptypes from "prop-types";
import { useSnackbar } from "notistack";
import WrappedButton from "../../../../../core/components/Button";
import { categories } from "../../../../../../core/tools/constants";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Document } from "../../../../../../domain/document";
import { useContractorState } from "../../../../../../application/contractor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 10px",
    width: "30vw",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  content: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  textField: {
    marginTop: "15px",
  },
  buttonContainer: {
    marginTop: "30px",
    // width: "100%",
    // width: "80%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    width: "100%",
  },
}));

// const selectLanguages = ["English", "Spanish"];

export default function EditDocumentContent(props: any) {
  const classes = useStyles();

  const { isEditing, onSuccessCallback } = props;

  // const { user } = useUserState();

  const { selectedContractor } = useContractorState();

  const { enqueueSnackbar } = useSnackbar();

  const inputFile: React.MutableRefObject<any> = useRef(null);

  const [titles, setTitles] = useState<string[]>([]);
  const [categoryName, setCategoryName] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [requirementMet, setRequirementMet] = useState(false);
  const [issuedOn, setIssuedOn] = useState(new Date());
  const [expireOn, setExpireOn] = useState(
    new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
  );
  const [fileName, setFilename] = useState("");

  const [file, setFile] = useState<File | null>(null);

  // const handleChange = (event: any) => {
  //   setLanguages(event.target.value);
  // };

  useEffect(() => {
    if (isEditing) {
      // setName(selectedContractor?.name ?? "");
      // setEmail(selectedContractor?.email ?? "");
      // setPhone(selectedContractor?.phone ?? "");
      // setAddress(selectedContractor?.contractorProps?.address ?? "");
      // setProviderId(selectedContractor?.contractorProps?.providerId ?? "");
      // setLicenceNumber(
      //   selectedContractor?.contractorProps?.licenseNumber ?? ""
      // );
      // setLanguages(selectedContractor?.contractorProps?.language ?? []);
      // setProfession(selectedContractor?.contractorProps?.profession ?? "");
    }
  }, [isEditing]);

  const validateForm = () => {
    return (
      (selectedTitle !== "" || newTitle === "") &&
      selectedTitle !== "choose" &&
      fileName !== ""
    );
  };

  const onButtonClick = () => {
    const isValidForm = validateForm();
    if (
      isValidForm &&
      file !== null &&
      selectedContractor.contractorProps?.id !== undefined
    ) {
      let data = new FormData();
      data.append("file", file);
      data.append("contractorId", selectedContractor.contractorProps?.id);
      data.append(
        "documentsData",
        JSON.stringify([
          new Document({
            title: selectedTitle === "new" ? newTitle : selectedTitle,
            filename: fileName,
            issued_on: issuedOn,
            expire_on: expireOn,
            requirement_met: requirementMet,
            category: categoryName,
          }),
        ])
      );
      onSuccessCallback(data);
    } else {
      enqueueSnackbar("Form invalid", { variant: "error" });
    }
  };

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let file = e.target.files[0];
      console.log(`onFileSelected file`, file);
      setFilename(file.name);
      setFile(file);
    }
  };

  console.log(`file`, file);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h3">
          {isEditing ? "Edit Document" : "Add Document"}
        </Typography>
        {/* <div className={classes.inputsContainer}> */}
        <FormControl fullWidth className={classes.textField}>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={categoryName}
            onChange={(e) => {
              const selectedCategory = e.target.value as string;
              setCategoryName(selectedCategory);
              const titles = categories.find(
                (item) => item.name === selectedCategory
              )?.titles;
              setTitles(titles ?? []);
              setSelectedTitle("");
            }}
          >
            {categories.map((item: any, index: number) => (
              <MenuItem key={index} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
            {/* <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.textField}>
          <InputLabel id="demo-simple-select-label">
            {categoryName === "" ? "Choose a Category..." : "Title"}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedTitle}
            disabled={categoryName === ""}
            onChange={(e) => {
              setSelectedTitle(e.target.value as string);
              // const selectedCategory = e.target.value as string;
              // setCategoryName(selectedCategory);
              // const titles = categories.find(
              //   (item) => item.name === selectedCategory
              // )?.titles;
              // setTitles(titles ?? []);
            }}
          >
            {titles.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
            {/* {titles.length !== 0 && ( */}
            <MenuItem value={"new"}>New Title</MenuItem>
            {/* )} */}
          </Select>
        </FormControl>
        {selectedTitle === "new" && (
          <TextField
            className={classes.textField}
            fullWidth
            label="Enter new title"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            fullWidth
            variant="inline"
            className={classes.textField}
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Issued On"
            value={issuedOn}
            onChange={(date) => {
              setIssuedOn(date as Date);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            fullWidth
            variant="inline"
            className={classes.textField}
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Expire On"
            value={expireOn}
            onChange={(date) => {
              setExpireOn(date as Date);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>

        <TextField
          className={classes.textField}
          fullWidth
          onFocus={() => {
            if (inputFile) {
              inputFile?.current?.click();
            }
          }}
          label="Document"
          value={fileName}
        />
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={onFileSelected}
        />
        <FormControlLabel
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            margin: "15px 0px",
            padding: "0px",
          }}
          control={
            <Switch
              checked={requirementMet}
              onChange={(e) => setRequirementMet(e.target.checked)}
              name="requirementMet"
              color="primary"
            />
          }
          label="Requirement Met?"
          labelPlacement="start"
        />
        <div className={classes.buttonContainer}>
          <WrappedButton className={classes.button} onClick={onButtonClick}>
            {isEditing ? "Edit" : "Add"}
          </WrappedButton>
        </div>
      </div>
    </div>
  );
}

// EditContractorModalContent.propTypes = {
//   isEditing: Proptypes.bool,
//   onSuccessCallback: Proptypes.func.isRequired,
// };
