import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  tableContainer: {
    maxHeight: "60vh",
  },
}));
