import React from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "../core/components/Routes";
import ErrorPage from "../Error";
import Activate from "./pages/Activate";
import ResetPassword from "./pages/ResetPassword";
import SignIn from "./pages/Sign/SignIn";
import SignUp from "./pages/Sign/SignUp";

export default function Auth() {
  return (
    <div>
      <Switch>
        <PublicRoute path="/auth/signIn/" component={SignIn} />
        <PublicRoute path="/auth/signUp" component={SignUp} />
        <Route path="/auth/activate/:id" component={Activate} />
        <Route path="/auth/reset-password/:id" component={ResetPassword} />
        <Route path="/auth/*" component={ErrorPage} />
      </Switch>
    </div>
  );
}
