import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  progressContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // background:
    //   "linear-gradient(60deg, " + theme.palette.primary.main + "10,  white)",
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
