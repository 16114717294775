import { LOCAL_STORAGE_TOKEN_KEY } from "../../../core/tools/env";
import GetAccessTokenDatasource, {
  IGetTokenDatasource,
} from "../../core/datasource/get_token_datasource";

export interface IUserLocalDatasource extends IGetTokenDatasource {
  setAccessToken(token: string): void;
  // getAccessToken(): string | null;
  removeAccessToken(): void;
}

export default class UserLocalDatasource
  extends GetAccessTokenDatasource
  implements IUserLocalDatasource
{
  removeAccessToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }

  setAccessToken(token: string): void {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  }

  // getAccessToken(): string | null {
  //   return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  // }
}

