import { IContractorRepository } from "../../domain/contractor/icontractor.repository";
import { EnqueueSnackbar } from "../../domain/core/utils";
import { User } from "../../domain/user/models/User";
import {
  ADD_CONTRACTOR,
  DELETE_CONTRACTOR,
  SET_CONTRACTORS,
  SET_FETCHING,
  SET_SELECTED_CONTRACTOR,
  SET_SUBMITING,
  UPDATE_CONTRACTOR,
  UPDATE_CONTRACTOR_DOCUMENTS,
} from "./misc";

export default class ContractorActions {
  private contractorRepository: IContractorRepository;

  constructor(contractorRepository: IContractorRepository) {
    this.contractorRepository = contractorRepository;
  }

  async uploadDocument(
    dispatch: any,
    data: FormData,
    enqueueSnackbar: EnqueueSnackbar,
    handleClose: any
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.contractorRepository.uploadDocument(data);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      dispatch({ type: SET_SUBMITING, payload: false });
      return;
    }
    dispatch({ type: UPDATE_CONTRACTOR_DOCUMENTS, payload: result.getValue() });
    dispatch({ type: SET_SUBMITING, payload: false });
    enqueueSnackbar("Contractor edited successfully", { variant: "success" });
    handleClose();
  }

  setSelectedContractor(dispatch: any, contractor?: User) {
    dispatch({ type: SET_SELECTED_CONTRACTOR, payload: contractor });
  }

  async fetchContractors(
    dispatch: any,
    companyId: string,
    enqueueSnackbar: EnqueueSnackbar
  ) {
    dispatch({ type: SET_FETCHING, payload: true });
    const result = await this.contractorRepository.getAllByCompanyId(companyId);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      dispatch({ type: SET_FETCHING, payload: false });
      return;
    }
    dispatch({ type: SET_CONTRACTORS, payload: result.getValue() });
    dispatch({ type: SET_FETCHING, payload: false });
  }

  async addContractor(
    dispatch: any,
    contractor: User,
    enqueueSnackbar: EnqueueSnackbar,
    handleClose: any
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.contractorRepository.create(contractor);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      dispatch({ type: SET_SUBMITING, payload: false });
      return;
    }
    dispatch({ type: ADD_CONTRACTOR, payload: result.getValue() });
    dispatch({ type: SET_SUBMITING, payload: false });
    enqueueSnackbar("Contractor added successfully", { variant: "success" });
    handleClose();
  }

  async updateContractor(
    dispatch: any,
    contractor: User,
    enqueueSnackbar: EnqueueSnackbar,
    handleClose: any
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.contractorRepository.update(contractor);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      dispatch({ type: SET_SUBMITING, payload: false });
      return;
    }
    dispatch({ type: UPDATE_CONTRACTOR, payload: contractor });
    dispatch({ type: SET_SUBMITING, payload: false });
    enqueueSnackbar("Contractor edited successfully", { variant: "success" });
    handleClose();
  }

  async deleteContractor(
    dispatch: any,
    id: string,
    enqueueSnackbar: EnqueueSnackbar,
    history: any
  ) {
    dispatch({ type: SET_SUBMITING, payload: true });
    const result = await this.contractorRepository.delete(id);
    if (result.isLeft) {
      enqueueSnackbar(result.getError(), { variant: "error" });
      dispatch({ type: SET_SUBMITING, payload: false });
      return;
    }
    dispatch({ type: DELETE_CONTRACTOR, payload: id });
    enqueueSnackbar("Contractor deleted successfully", { variant: "success" });
    history.goBack();
  }
}
