export class Result<T> {
  public isRigth: boolean;
  public isLeft: boolean;
  // private _error: string;
  private _value: T | string;

  private constructor(isRigth: boolean, value: T | string) {
    this.isRigth = isRigth;
    this.isLeft = !isRigth;
    this._value = value;

    Object.freeze(this);
  }

  public getValue(): T {
    if (!this.isRigth) {
      console.log("getValue()", this._value);
      throw new Error(
        "Can't get the value of an error result. Use 'errorValue' instead."
      );
    }

    return this._value as T;
  }

  public getError(): string {
    if (!this.isLeft) {
      console.log("getError()", this._value);
      throw new Error("Can't get the error value from a correct result");
    }
    return this._value as string;
  }

  public static rigth<U>(value: U): Result<U> {
    return new Result<U>(true, value);
  }

  public static left<U>(error: string): Result<U> {
    return new Result<U>(false, error);
  }

  // public static combine(results: Result<any>[]): Result<any> {
  //   for (let result of results) {
  //     if (result.isLeft) return result;
  //   }
  //   return Result.rigth<string>("");
  // }
}
