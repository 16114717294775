import { LOCAL_STORAGE_TOKEN_KEY } from "../../../core/tools/env";

export interface IGetTokenDatasource {
  getAccessToken(): string | null;
}

export default class GetAccessTokenDatasource implements IGetTokenDatasource {
  getAccessToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  }
}

