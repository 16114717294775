import React, { useEffect, useState } from "react";
import {
  makeStyles,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Input,
  MenuItem,
} from "@material-ui/core";
// import Proptypes from "prop-types";
import { useUserState } from "../../../../../../application/user";
import { useSnackbar } from "notistack";
import { OWNER_ROLE } from "../../../../../../core/tools/constants";
import { User } from "../../../../../../domain/user/models/User";
import WrappedButton from "../../../../../core/components/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 10px",
    maxWidth: "30vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90vw",
    },
  },
  content: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  textField: {
    marginTop: "15px",
  },
  buttonContainer: {
    marginTop: "30px",
    // width: "100%",
    // width: "80%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    width: "100%",
  },
}));

const selectLanguages = ["English", "Spanish"];

export default function EditProfileModalContent(props: any) {
  const classes = useStyles();

  const { onSuccessCallback } = props;

  const { user } = useUserState();

  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [providerId, setProviderId] = useState("");
  const [licenceNumber, setLicenceNumber] = useState("");
  const [profession, setProfession] = useState("");
  const [languages, setLanguages] = useState<string[]>([]);
  const [company, setCompany] = useState({ id: user?.company?.id, name: "" });

  const handleChange = (event: any) => {
    setLanguages(event.target.value);
  };

  useEffect(() => {
    if (user) {
      setName(user?.name ?? "");
      setEmail(user?.email ?? "");
      setPhone(user?.phone ?? "");
      if (user?.company) {
        setCompany((company: any) => ({
          ...company,
          name: user?.company?.name,
        }));
      }
      if (user?.contractorProps) {
        setAddress(user?.contractorProps?.address ?? "");
        setProviderId(user?.contractorProps?.providerId ?? "");
        setLicenceNumber(user?.contractorProps?.licenseNumber ?? "");
        setLanguages(user?.contractorProps?.language ?? []);
        setProfession(user?.contractorProps?.profession ?? "");
      }
    }
  }, [user]);

  const validateForm = () => {
    return name !== "" && email !== "";
  };

  const getUser = () => {
    return user.contractorProps
      ? new User({
          id: user.id,
          name: name,
          email: email,
          phone: phone,
          contractorProps: {
            id: user.contractorProps.id,
            profession: profession,
            address: address,
            providerId: providerId,
            licenseNumber: licenceNumber,
            language: languages,
          },
        })
      : new User({
          id: user.id,
          email: email,
          name: name,
          phone: phone,
          company: company,
        });
  };

  const onButtonClick = () => {
    const isValidForm = validateForm();
    if (isValidForm) {
      onSuccessCallback(getUser());
    } else {
      enqueueSnackbar("Form invalid", { variant: "error" });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h3">Edit your profile</Typography>
        {/* <div className={classes.inputsContainer}> */}
        <TextField
          className={classes.textField}
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        {user.role === OWNER_ROLE && (
          <TextField
            className={classes.textField}
            fullWidth
            label="Company"
            value={company.name}
            onChange={(e) => setCompany({ ...company, name: e.target.value })}
          />
        )}
        {user.contractorProps && (
          <>
            <TextField
              className={classes.textField}
              fullWidth
              label="Profession"
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
            />
            <TextField
              className={classes.textField}
              fullWidth
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              className={classes.textField}
              fullWidth
              label="Provider ID"
              value={providerId}
              onChange={(e) => setProviderId(e.target.value)}
            />
            <TextField
              className={classes.textField}
              fullWidth
              label="Licence Number"
              value={licenceNumber}
              onChange={(e) => setLicenceNumber(e.target.value)}
            />
            <FormControl fullWidth className={classes.textField}>
              <InputLabel id="demo-mutiple-chip-label">Languages</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={languages}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected: any) => (
                  <div className={classes.chips}>
                    {selected.map((value: any) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chips}
                      />
                    ))}
                  </div>
                )}
                // MenuProps={MenuProps}
              >
                {selectLanguages.map((name: any) => (
                  <MenuItem
                    key={name}
                    value={name}
                    // style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {/* </div> */}
        <div className={classes.buttonContainer}>
          <WrappedButton className={classes.button} onClick={onButtonClick}>
            Edit
          </WrappedButton>
        </div>
      </div>
    </div>
  );
}

// EditProfileModalContent.propTypes = {
//   onSuccessCallback: Proptypes.func.isRequired,
// };
