import { makeStyles } from "@material-ui/core";
import { drawerWidth } from "../../core/tools/constants";

// export default makeStyles((theme) => ({}));
export default makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    padding: "10px",
  },
  content: {
    marginTop: "50px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },
}));
