import { Typography } from "@material-ui/core";
import React from "react";
import { useUserState } from "../../../../../../application/user";
import useStyles from "./styles";
import profile from "../../../../../core/assets/images/profile.png";
import ProfileAvatar from "../../../../components/ProfileAvatar";

export default function ProfileHighlighs() {
  const classes = useStyles();

  const { user } = useUserState();

  return (
    <div className={classes.root}>
      <ProfileAvatar imageSrc={profile} />
      <div className={classes.infoContainer}>
        <Typography>
          {user?.contractorProps?.profession ?? user?.company?.name}
        </Typography>
        <Typography>{user?.phone ?? "(---) --- ----"}</Typography>
        <Typography>{user?.email}</Typography>
      </div>
    </div>
  );
}
