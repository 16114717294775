import React from "react";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import { Menu } from "@material-ui/icons";
import NavbarLinks from "./NavbarLinks";
import { useUserState } from "../../../../application/user";

export default function Navbar(props: any) {
  const classes = useStyles();
  const { user } = useUserState();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Typography
            component="span"
            // className={classes.title}
            // component="span"
            // size="md"
            // weight="normal"
          >
            <Box
              fontSize={20}
              // fontWeight="bold"
            >
              {user.company?.name}
            </Box>
          </Typography>
          {/* <Button color="transparent" className={classes.title}>
        {makeBrand()}
      </Button> */}
        </div>
        <Hidden smDown implementation="css">
          <NavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
