import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalCard: {
    outline: 0,
    // minWidth: "30vw",
    // minHeight: "20vh",
  },
}));
