import { Typography } from "@material-ui/core";
import React from "react";
import Legend from "../Legend";
import useStyles from "./styles";

type TabHeaderProps = {
  title: string;
};

export default function TabHeader(props: TabHeaderProps) {
  const classes = useStyles();
  const { title } = props;
  return (
    <div className={classes.root}>
      <Typography variant="h5">{title}</Typography>
      <Legend />
    </div>
  );
}
