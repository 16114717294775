import { container, InjectionToken } from "tsyringe";
import { registerContractorDependencies } from "./contractor_dependencies";

import { registerUserDependencies } from "./user_dependencies";

export function sl<T>(dependency: InjectionToken<T>): T {
  return container.resolve<T>(dependency);
}

export function registerDependencies() {
  registerUserDependencies();
  registerContractorDependencies();
}
