import React from "react";
// import PropTypes from "prop-types";
import useStyles from "./styles";

export default function InfoDataItem(props: any) {
  const classes = useStyles();

  const { label, value } = props;

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
}

// InfoDataItem.propTypes = {
//   label: PropTypes.string.isRequired,
//   value: PropTypes.string.isRequired,
// };
