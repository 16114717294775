import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    // justifyContent: "flex-start",
    // alignItems: "center",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));
