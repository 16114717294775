import React from "react";
import { initialValues, UserDispatch } from "./misc";
import reducer from "./reducer";

var UserStateContext = React.createContext(initialValues);
var UserDispatchContext = React.createContext<UserDispatch>(() => null);

function UserProvider({ children }: any) {
  var [state, dispatch] = React.useReducer(reducer, initialValues);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch };
