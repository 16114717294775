export interface ICompanyProps {
  id?: string;
  name?: string;
}

export class Company {
  id?: string;
  name?: string;

  constructor(props: ICompanyProps) {
    this.id = props.id;
    this.name = props.name;
  }
}
