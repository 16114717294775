import { Button } from "@material-ui/core";
import classNames from "classnames";
import useStyles from "./styles";

const WrappedButton = (props: any) => {
  let { children, variant, color, onClick, className, ...rest } = props;

  const classes: any = useStyles();

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[color]]: color,
    [className]: className,
  });

  return (
    <Button
      className={btnClasses}
      variant={variant}
      // color={color}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

WrappedButton.defaultProps = {
  variant: "contained",
  color: "primary",
};

export default WrappedButton;
