import React from "react";
import { useUserState } from "../../../../../../../../application/user";
import { languagesFormater } from "../../../../../../../../core/tools/helpers";
import InfoDataItem from "../../../../../../components/InfoDataItem";
import useStyles from "./styles";

export default function PersonalInformation() {
  const classes = useStyles();

  const { user } = useUserState();

  return (
    <div className={classes.root}>
      <div className={classes.itemContainer}>
        <InfoDataItem label={"Name"} value={user?.name ?? ""} />
        {user?.contractorProps && (
          <>
            <InfoDataItem
              label={"Address"}
              value={user?.contractorProps?.address ?? ""}
            />
            <InfoDataItem label={"Company"} value={user?.company?.name ?? ""} />
          </>
        )}
      </div>
      <div className={classes.itemContainer}>
        {user.contractorProps && (
          <>
            <InfoDataItem
              label={"Provider ID"}
              value={user?.contractorProps?.providerId ?? ""}
            />
            <InfoDataItem
              label={"License Number"}
              value={user?.contractorProps?.licenseNumber ?? ""}
            />
            <InfoDataItem
              label={"Languages"}
              value={languagesFormater(user?.contractorProps?.language ?? [])}
            />
          </>
        )}
      </div>
    </div>
  );
}
