import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useUserDispatch } from "../../../../application/user";
import useStyles from "./styles";
import logo from "../../../core/assets/images/apd-desk.png";
import { useSnackbar } from "notistack";
import { useInjection } from "../../../../core/dependency_injection/ioc.react";
import UserActions from "../../../../application/user/actions";

export default function SignIn() {
  document.title = "APD Desk | Sign In";

  const classes = useStyles();
  const history = useHistory();

  const userDispatch = useUserDispatch();

  const userActions = useInjection<UserActions>("UserActions");

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {/* <Typography variant="h4"> */}
          <div className={classes.logoContainer}>
            {/* <Link className={classes.link} to="/"> */}
            <img
              src={logo}
              alt="APD Desk"
              className={classes.logo}
              onClick={() => {
                history.replace("/");
              }}
            />
            {/* </Link> */}
          </div>
          {/* </Typography> */}
          <Typography className={classes.subtitle} variant="h6">
            Welcome back
          </Typography>
          <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
            <TextField
              className={classes.margin}
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <TextField
            className={classes.margin}
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
            <FormControl fullWidth className={classes.margin}>
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                userActions.signIn(
                  userDispatch,
                  email,
                  password,
                  history,
                  enqueueSnackbar
                );
              }}
            >
              Sign In
            </Button>
            <Typography className={classes.margin} variant="body1">
              <Link className={classes.link} to="#">
                Forgot password?
              </Link>
            </Typography>
            <Typography className={classes.margin} variant="body1">
              New to APD Desk?{" "}
              <Link className={classes.link} to="/auth/signup">
                Join now
              </Link>
            </Typography>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
