import { Document } from "../../document";

export interface IContractorProps {
  id?: string;
  address?: string;
  providerId?: string;
  licenseNumber?: string;
  profession?: string;
  language?: string[];
  documents?: Document[];
}

export class Contractor {
  id?: string;
  address?: string;
  providerId?: string;
  licenseNumber?: string;
  profession?: string;
  language?: string[];
  documents?: Document[];

  constructor(props: IContractorProps) {
    this.id = props.id;
    this.address = props.address;
    this.providerId = props.providerId;
    this.licenseNumber = props.licenseNumber;
    this.profession = props.profession;
    this.language = props.language;
    this.documents = props.documents;
  }
}
