import { Home } from "@material-ui/icons";
import PanelHeader from "../../components/PanelHeader";
import { useUserDispatch, useUserState } from "../../../../application/user";
import { useInjection } from "../../../../core/dependency_injection";
import UserActions from "../../../../application/user/actions";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";

export default function Dashboard() {
  document.title = "APD Desk | Business Dashboard";

  const { user } = useUserState();
  const userDispatch = useUserDispatch();

  const userActions = useInjection<UserActions>("UserActions");

  const { enqueueSnackbar } = useSnackbar();

  console.log(`Dashboard user`, user);

  return (
    <div>
      <PanelHeader icon={<Home />} title="Business Dashboard" />
      <div>
        {user.active
          ? "Your account is active"
          : "Your account isn't activated"}
      </div>
      {!user.active && (
        <div>
          <Button
            onClick={() => {
              userActions.sendEmail(user.email, user.name, enqueueSnackbar);
            }}
          >
            Send Email
          </Button>
        </div>
      )}
      <div>
        <Button
          onClick={() => {
            userActions.sendResetPasswordEmail(
              userDispatch,
              user.email,
              enqueueSnackbar
            );
          }}
        >
          Send Password Reset Email
        </Button>
      </div>
    </div>
  );
}
