import React from "react";
// import PropTypes from "prop-types";
import { Typography, Hidden, Breadcrumbs } from "@material-ui/core";
import useStyles from "./styles";
import MobileMenu from "./components/MobileMenu";
import WrappedButton from "../../../core/components/Button";
import { Link } from "react-router-dom";

export default function PanelHeader(props: any) {
  const { title, icon, breadcrumbsRoutes, actions } = props;

  const classes = useStyles();

  // console.log(`breadCrumbsRoutes`, breadcrumbsRoutes);

  return (
    <div className={classes.root}>
      {breadcrumbsRoutes ? (
        <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          {breadcrumbsRoutes.map((item: any, index: any) => {
            return item.to ? (
              <Link
                key={index}
                color="inherit"
                to={item.to}
                // onClick={handleClick}
                className={classes.link}
              >
                {/* {item.icon && <item.icon className={classes.icon} />} */}
                {item.icon && (
                  <span className={classes.icon}>
                    <item.icon />
                  </span>
                )}
                {item.label}
              </Link>
            ) : (
              <Typography
                key={index}
                color="textPrimary"
                className={classes.text}
              >
                {/* {item.icon && <item.icon className={classes.icon} />} */}
                {item.icon && (
                  <span className={classes.icon}>
                    <item.icon />
                  </span>
                )}
                {item.label}
              </Typography>
            );
          })}
        </Breadcrumbs>
      ) : (
        <Typography color="textPrimary" className={classes.text}>
          {/* {item.icon && <item.icon className={classes.icon} />} */}
          {icon && <span className={classes.icon}>{icon}</span>}
          {title}
        </Typography>
      )}
      {actions && (
        <>
          <Hidden smDown>
            <div>
              {actions.map((item: any, index: number) => (
                <WrappedButton
                  key={index}
                  color={item.color}
                  startIcon={<item.icon />}
                  onClick={item.onClick}
                  style={{ marginLeft: "10px" }}
                >
                  {item.label}
                </WrappedButton>
              ))}
            </div>
          </Hidden>
          <Hidden mdUp>
            <MobileMenu actions={actions} />
          </Hidden>
        </>
      )}
    </div>
  );
}

// PanelHeader.propTypes = {
//   title: PropTypes.string,
//   icon: PropTypes.any,
//   breadcrumbsRoutes: PropTypes.arrayOf(
//     PropTypes.shape({
//       icon: PropTypes.any,
//       label: PropTypes.string,
//       to: PropTypes.string,
//     })
//   ),
//   actions: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       icon: PropTypes.any,
//       onClick: PropTypes.func,
//     })
//   ),
// };
