import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  avatarContainer: {
    position: "relative",
    top: -10,
    zIndex: 3,
    width: "120px",
    height: "120px",
    backgroundColor: "grey",
    borderRadius: "60px",
    marginLeft: "20px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
    },
  },
  image: {
    borderRadius: "60px",
  },
  edit: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    bottom: 25,
    color: theme.palette.common.black,
    cursor: "pointer",
    backgroundColor: "#eee",
  },
}));
