import { container } from "tsyringe";
import { IContractorRepository } from "../../domain/contractor/icontractor.repository";
import { sl } from "./register_dependencies";
import ContractorActions from "../../application/contractor/actions";
import ContractorLocalDatasource, {
  IContractorLocalDatasource,
} from "../../infrastructure/contractor/datasources/local";
import ContractorRemoteDatasource, {
  IContractorRemoteDatasource,
} from "../../infrastructure/contractor/datasources/remote";
import ContractorRepository from "../../infrastructure/contractor/contractor.repository";

export function registerContractorDependencies() {
  container.register<IContractorLocalDatasource>("IContractorLocalDatasource", {
    useClass: ContractorLocalDatasource,
  });
  container.register<IContractorRemoteDatasource>(
    "IContractorRemoteDatasource",
    {
      useClass: ContractorRemoteDatasource,
    }
  );

  container.register<IContractorRepository>("IContractorRepository", {
    useValue: new ContractorRepository(
      sl<IContractorRemoteDatasource>("IContractorRemoteDatasource"),
      sl<IContractorLocalDatasource>("IContractorLocalDatasource")
    ),
  });

  container.register<ContractorActions>("ContractorActions", {
    useValue: new ContractorActions(
      sl<IContractorRepository>("IContractorRepository")
    ),
  });
}
