export interface DocumentProps {
  id?: string;
  title: string;
  filename: string;
  url?: string;
  category: string;
  requirement_met?: boolean;
  expire_on: Date;
  issued_on: Date;
}

export default class Document {
  id?: string;
  title: string;
  filename: string;
  category: string;
  url?: string;
  requirement_met?: boolean;
  expire_on: Date;
  issued_on: Date;

  public constructor(props: DocumentProps) {
    this.id = props.id;
    this.title = props.title;
    this.filename = props.filename;
    this.category = props.category;
    this.url = props.url;
    this.requirement_met = props.requirement_met;
    this.expire_on = props.expire_on;
    this.issued_on = props.issued_on;
  }
}
