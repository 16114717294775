import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    padding: "10px",
    width: "100%",
    height: "100%",
    minWidth: "40vw",
    minHeight: "70vh",
    maxWidth: "50vw",
    maxHeight: "100vh",
  },
  document: {
    height: "80%",
    width: "100%",
  },
  pages: {
    // position: "relative",
    // right: 0,
    // top: 0,
  },
  buttons: {
    // position: "relative",
    // right: 0,
    // bottom: 0,
  },
}));
