import { Card } from "@material-ui/core";
import React from "react";
import { User } from "../../../../domain/user/models/User";
import ContractorHighlighs from "./components/Highlighs";
import InfoTabs from "./components/InfoTabs";

type ContractorProfileCardProps = {
  selectedContractor: User;
};

export default function ContractorProfileCard(
  props: ContractorProfileCardProps
) {
  const { selectedContractor } = props;

  return (
    <Card
      style={{ position: "relative", overflow: "visible", marginTop: "10px" }}
    >
      <ContractorHighlighs selectedContractor={selectedContractor} />
      <InfoTabs />
    </Card>
  );
}
