import React from "react";
import {
  TableCell,
  TableRow,
  withStyles,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  IconButton,
  Link,
} from "@material-ui/core";
// import PropTypes from "prop-types";
import { getDaysBetween, getRowColor } from "../../../../../core/tools/helpers";
import useStyles from "./styles";
import { Document } from "../../../../../domain/document";
import { CloudDownload, Visibility } from "@material-ui/icons";
import WrappedModal from "../../Modal";
import useOpenable from "../../../hooks/useOpenable";
import PreviewDocument from "./components/PreviewDocument";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.paper,
    // color: theme.palette.common.white,
  },
  // body: {
  //   fontSize: 14,
  // },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
  // hover: {
  // cursor: "",
  // },
}))(TableRow);

type DocumentsTableProps = {
  data: Document[];
};

const DocumentsTable = (props: DocumentsTableProps) => {
  const { data } = props;

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [url, setUrl] = React.useState("");

  const [open, handleOpen, handleClose] = useOpenable();

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper className={classes.table}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* {columns.map((column: any, index: any) => ( */}
                <StyledTableCell align="left">Requirement Met?</StyledTableCell>
                <StyledTableCell align="left">Title</StyledTableCell>
                <StyledTableCell align="left">Expires In</StyledTableCell>
                <StyledTableCell align="left">Issued On</StyledTableCell>
                <StyledTableCell align="left">Expires On</StyledTableCell>
                <StyledTableCell align="left">View</StyledTableCell>
                <StyledTableCell align="left">Download</StyledTableCell>
                {/* ))} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const days = getDaysBetween(
                    new Date(row.issued_on),
                    new Date(row.expire_on)
                  );
                  const color = getRowColor(row);
                  return (
                    <StyledTableRow
                      // hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      style={{
                        backgroundColor: color,
                      }}
                      // onClick={() => {
                      //   onClickCallback(row);
                      // }}
                    >
                      <TableCell>
                        {row.requirement_met ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{Math.round(days / 30)} Months</TableCell>
                      <TableCell>
                        {row.issued_on.toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {row.expire_on.toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          // component={Link}
                          color="primary"
                          aria-label="view"
                          // href={row.url}
                          onClick={() => {
                            setUrl(row.url ?? "");
                            handleOpen();
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          component={Link}
                          color="inherit"
                          aria-label="download"
                          href={row.url}
                          // onClick={() => {}}
                        >
                          <CloudDownload />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <WrappedModal open={open} handleClose={handleClose}>
        <PreviewDocument url={url} />
      </WrappedModal>
    </>
  );
};

DocumentsTable.defaultProps = {
  dense: false,
};

export default DocumentsTable;
