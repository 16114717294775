import { container } from "tsyringe";
import UserActions from "../../application/user/actions";
import { IUserRepository } from "../../domain/user/iuser.repository";
import UserLocalDatasource, {
  IUserLocalDatasource,
} from "../../infrastructure/user/datasources/local";
import UserRemoteDatasource, {
  IUserRemoteDatasource,
} from "../../infrastructure/user/datasources/remote";
import UserRepository from "../../infrastructure/user/user.repository";
import { sl } from "./register_dependencies";

export function registerUserDependencies() {
  container.register<IUserLocalDatasource>("IUserLocalDatasource", {
    useClass: UserLocalDatasource,
  });
  container.register<IUserRemoteDatasource>("IUserRemoteDatasource", {
    useClass: UserRemoteDatasource,
  });

  container.register<IUserRepository>("IUserRepository", {
    useValue: new UserRepository(
      sl<IUserRemoteDatasource>("IUserRemoteDatasource"),
      sl<IUserLocalDatasource>("IUserLocalDatasource")
    ),
  });

  container.register<UserActions>("UserActions", {
    useValue: new UserActions(sl<IUserRepository>("IUserRepository")),
  });
}
