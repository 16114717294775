import React from "react";
import { Card } from "@material-ui/core";
import { AccountCircle, Edit } from "@material-ui/icons";
import EditProfileModalContent from "./components/EditProfileModalContent";
import ProfileHighlighs from "./components/ProfileHighlighs";
import ProfileTabs from "./components/ProfileTabs";
import { useSnackbar } from "notistack";
import { useUserDispatch, useUserState } from "../../../../application/user";
import { useInjection } from "../../../../core/dependency_injection";
import UserActions from "../../../../application/user/actions";
import useOpenable from "../../../core/hooks/useOpenable";
import PanelHeader from "../../components/PanelHeader";
import WrappedModal from "../../../core/components/Modal";
import { User } from "../../../../domain/user/models/User";
import ContractorProfileCard from "../../components/ContractorProfileCard";

export default function Profile() {
  document.title = "APD Desk | Profile";

  const { user } = useUserState();
  const userDispatch = useUserDispatch();

  const userActions = useInjection<UserActions>("UserActions");

  const [open, handleOpen, handleClose] = useOpenable();

  const { enqueueSnackbar } = useSnackbar();

  // console.log(`user`, user);

  const panelActions = [
    {
      label: "Edit",
      icon: Edit,
      onClick: () => {
        handleOpen();
      },
    },
  ];

  return (
    <>
      <div>
        <PanelHeader
          icon={<AccountCircle />}
          title="Profile"
          actions={panelActions}
        />
        {user.contractorProps ? (
          <ContractorProfileCard selectedContractor={user} />
        ) : (
          <Card
            style={{
              position: "relative",
              overflow: "visible",
              marginTop: "10px",
            }}
          >
            <ProfileHighlighs />
            <ProfileTabs />
          </Card>
        )}
      </div>
      <WrappedModal open={open} handleClose={handleClose}>
        <EditProfileModalContent
          onSuccessCallback={(user: User) => {
            // console.log(`profile user`, user);
            userActions.updateUser(userDispatch, user, enqueueSnackbar);
          }}
        />
      </WrappedModal>
    </>
  );
}
