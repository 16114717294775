import { Card, Modal } from "@material-ui/core";
import useStyles from "./styles";

const WrappedModal = (props: any) => {
  const classes = useStyles();

  const { children, open, handleClose } = props;

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Card className={classes.modalCard}>{children}</Card>
    </Modal>
  );
};

export default WrappedModal;
