import { makeStyles } from "@material-ui/core";
import { container } from "../../../../core/tools/constants";

export default makeStyles((theme?: any) => ({
  appBar: {
    backgroundColor: "white",
    position: "absolute",
    width: "100%",
    zIndex: 1029,
    color: theme.palette.text.primary,
    border: "0",
    borderRadius: "3px",
    transition: "all 150ms ease 0s",
    maxHeight: "90px",
    display: "block",
  },
  container: {
    ...container,
  },
  flex: {
    flex: 1,
  },
}));
