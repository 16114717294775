import { makeStyles } from "@material-ui/core";
import {
  drawerWidth,
  boxShadow,
  transition,
} from "../../../../core/tools/constants";
import { hexToRgb } from "../../../../core/tools/helpers";

export default makeStyles((theme) => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    // backgroundColor: "rgba(" + hexToRgb("#000") + ", 0.8)",
    backgroundColor: "#22273d",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: 1,
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: 1032,
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "rgb(0,0,0,0.3)",
    },
    "&:hover,&:focus,&:visited,&": {
      color: "#fff",
    },
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    // margin: "10px 15px 0",
    // borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb("#FFF") + ", 0.8)",
  },
  itemText: {
    margin: "0",
    lineHeight: "30px",
    fontSize: "15px",
    color: "#FFF",
  },
  activeColor: {
    backgroundColor: theme.palette.primary.main,
    // boxShadow:
    //   "0 12px 20px -10px rgba(" +
    //   hexToRgb(theme.palette.primary.main) +
    //   ",.28), 0 4px 20px 0 rgba(" +
    //   hexToRgb("#000") +
    //   ",.12), 0 7px 8px -5px rgba(" +
    //   hexToRgb(theme.palette.primary.main) +
    //   ",.2)",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
      // boxShadow:
      //   "0 12px 20px -10px rgba(" +
      //   hexToRgb(theme.palette.primary.main) +
      //   ",.28), 0 4px 20px 0 rgba(" +
      //   hexToRgb("#000") +
      //   ",.12), 0 7px 8px -5px rgba(" +
      //   hexToRgb(theme.palette.primary.main) +
      //   ",.2)",
    },
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: "260px",
    zIndex: 4,
    overflowScrolling: "touch",
  },
  brand: {
    display: "flex",
    position: "relative",
    width: "100%",
    padding: "15px 15px",
    maxHeight: "65px",
    ...boxShadow,
    zIndex: 4,
    // "&:after": {
    //   content: '""',
    //   position: "absolute",
    //   bottom: "0",
    //   height: "1.5px",
    //   left: 0,
    //   // right: "15px",
    //   // width: "calc(100% - 30px)",
    //   width: drawerWidth,
    //   backgroundColor: "rgba(" + hexToRgb("#AAAAAA") + ", 0.3)",
    // },
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "90px",
  },
}));
