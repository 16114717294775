import {
  SET_ACTIVATE_MESSAGE,
  SET_ACTIVE,
  SET_LOADING,
  SET_SUBMITING,
  SET_USER,
  SIGN_FAILURE,
  SIGN_OUT_SUCCESS,
  UPDATE_USER,
  UserAction,
  UserState,
} from "./misc";

export default function reducer(state: UserState, action: UserAction) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_SUBMITING:
      return { ...state, isSubmiting: action.payload };
    case SET_ACTIVATE_MESSAGE:
      return { ...state, activateMessage: action.payload };
    case SIGN_OUT_SUCCESS:
      return { ...state, isAuthenticated: false, isLoading: false };
    case SIGN_FAILURE:
      return { ...state, isAuthenticated: false, isLoading: false };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: !!action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case SET_ACTIVE:
      return {
        ...state,
        user: { ...state.user, active: action.payload },
      };
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}
