import { User } from "../../domain/user/models/User";
import {
  ContractorAction,
  ContractorState,
  ADD_CONTRACTOR,
  DELETE_CONTRACTOR,
  SET_CONTRACTORS,
  SET_FETCHING,
  SET_SELECTED_CONTRACTOR,
  SET_SUBMITING,
  UPDATE_CONTRACTOR,
  UPDATE_CONTRACTOR_DOCUMENTS,
} from "./misc";

export default function reducer(
  state: ContractorState,
  action: ContractorAction
) {
  switch (action.type) {
    case SET_SELECTED_CONTRACTOR:
      return { ...state, selectedContractor: action.payload };
    case SET_FETCHING:
      return { ...state, isFetching: action.payload };
    case SET_CONTRACTORS:
      return { ...state, contractors: action.payload };
    case ADD_CONTRACTOR:
      return { ...state, contractors: [...state.contractors, action.payload] };
    case DELETE_CONTRACTOR:
      return {
        ...state,
        contractors: state.contractors.filter(
          (item: any) => item.id !== action.payload
        ),
      };
    case UPDATE_CONTRACTOR_DOCUMENTS:
      const selectedContractor = state.selectedContractor;
      var contractors = state.contractors;
      const newUser = new User({
        ...selectedContractor,
        contractorProps: {
          ...selectedContractor.contractorProps,
          documents: action.payload,
        },
        // ...tempContractors[index],
        // ...contractor,
      });
      const i = contractors.findIndex(
        (item: any) => item.id === selectedContractor.id
      );
      contractors[i] = newUser;
      console.log(`tempContractors[index] despues`, contractors[i]);
      console.log(`state.selectedContractor`, state.selectedContractor);
      console.log(`user`, newUser);
      return {
        ...state,
        contractors: contractors,
        selectedContractor: newUser,
      };
    case UPDATE_CONTRACTOR:
      const contractor = action.payload;
      var tempContractors = state.contractors;
      const index = tempContractors.findIndex(
        (item: any) => item.id === contractor.id
      );
      console.log(`contractor`, contractor);
      console.log(`tempContractors[index] antes`, tempContractors[index]);
      const user = new User({
        ...tempContractors[index],
        name: contractor.name,
        phone: contractor.phone,
        email: contractor.email,
        contractorProps: {
          ...contractor.contractorProps,
          documents: tempContractors[index].contractorProps?.documents,
        },
        // ...tempContractors[index],
        // ...contractor,
      });
      tempContractors[index] = user;
      console.log(`tempContractors[index] despues`, tempContractors[index]);
      console.log(`state.selectedContractor`, state.selectedContractor);
      console.log(`user`, user);
      return {
        ...state,
        contractors: tempContractors,
        selectedContractor: user,
      };
    case SET_SUBMITING:
      return { ...state, isSubmiting: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}
