import React from "react";
import { useContractorState } from "../../../../../../../../application/contractor";
import { languagesFormater } from "../../../../../../../../core/tools/helpers";
import InfoDataItem from "../../../../../InfoDataItem";
import useStyles from "./styles";

export default function GeneralInformation() {
  const classes = useStyles();

  const { selectedContractor } = useContractorState();

  return (
    <div className={classes.root}>
      <div className={classes.itemContainer}>
        <InfoDataItem label={"Name"} value={selectedContractor?.name ?? ""} />
        <InfoDataItem
          label={"Address"}
          value={selectedContractor?.contractorProps?.address ?? ""}
        />
        <InfoDataItem
          label={"Company"}
          value={selectedContractor?.company?.name ?? ""}
        />
      </div>
      <div className={classes.itemContainer}>
        <InfoDataItem
          label={"Provider ID"}
          value={selectedContractor?.contractorProps?.providerId ?? ""}
        />
        <InfoDataItem
          label={"License Number"}
          value={selectedContractor?.contractorProps?.licenseNumber ?? ""}
        />
        <InfoDataItem
          label={"Languages"}
          value={languagesFormater(
            selectedContractor?.contractorProps?.language ?? []
          )}
        />
      </div>
    </div>
  );
}
