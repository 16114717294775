import { Company } from "../../../domain/company/models/Company";

export interface ICompanyProps {
  id?: string;
  name?: string;
}

export class CompanyDto {
  id?: string;
  name?: string;

  constructor(props: ICompanyProps) {
    this.id = props.id;
    this.name = props.name;
  }

  public static fromDomain(company: Company): CompanyDto {
    return new CompanyDto({
      id: company.id,
      name: company.name,
    });
  }

  public toDomain(): Company {
    return new Company({ id: this.id, name: this.name });
  }
}
