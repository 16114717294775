import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useUserDispatch, useUserState } from "../../../../application/user";
import UserActions from "../../../../application/user/actions";
import { useInjection } from "../../../../core/dependency_injection";
import logo from "../../../core/assets/images/apd-desk.png";
import useStyles from "./styles";

type RouteParams = {
  id: string;
};

export default function ResetPassword() {
  const classes = useStyles();

  const history = useHistory();

  const { id } = useParams<RouteParams>();

  const { isSubmiting } = useUserState();
  const userDispatch = useUserDispatch();

  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const userActions = useInjection<UserActions>("UserActions");

  const { enqueueSnackbar } = useSnackbar();

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const checkPasswordMatch = () => {
    return newPassword === confirmPassword;
  };

  const handleOnChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const passwordMatch = checkPasswordMatch();
    if (passwordMatch) {
      userActions.resetPassword(
        userDispatch,
        id,
        newPassword,
        history,
        enqueueSnackbar
      );
    } else {
      enqueueSnackbar("The passwords doesn't match");
    }
  };

  return (
    <div className={classes.root}>
      <Link to="/">
        <img src={logo} alt="APD Desk Logo" width="300px" />
      </Link>
      <Card className={classes.card}>
        {isSubmiting && <LinearProgress />}
        <CardContent className={classes.cardContent}>
          <Typography variant="h5">Reset your password</Typography>
          <FormControl fullWidth className={classes.margin}>
            <InputLabel htmlFor="standard-adornment-password1">
              New Password
            </InputLabel>
            <Input
              id="standard-adornment-password1"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth className={classes.margin}>
            <InputLabel htmlFor="standard-adornment-password2">
              Confirm Password
            </InputLabel>
            <Input
              id="standard-adornment-password2"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            disabled={isSubmiting}
            fullWidth
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleOnChange}
          >
            Change
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
