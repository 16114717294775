import { colors } from "@material-ui/core";
import { hexToRgb } from "./helpers";

export const drawerWidth = 260;

export const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

export const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
};

export const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgb("#000") +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgb("#000") +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb("#000") +
    ", 0.2)",
};

export const OWNER_ROLE = "Owner";
export const ADMIN_ROLE = "Admin";
export const CONTRACTOR_ROLE = "Contractor";

export class DocumentTableRowsColors {
  static not_met = "#FFFFFF";
  static met = colors.green[100];
  static expire_soon = colors.orange[100];
  static expired = colors.red[100];
}

export type Category = {
  name: string;
  titles: string[];
};

export class Categories {
  static personalInformation = "Personal Information";
  static legalDocumentation = "Legal Documentation";
  static educationalLevel = "Educational Level";
  static trainingCertificates = "Training / Certificates";
  static firstAid = "C.P.R., First Aid";
}

export const mockData = [
  {
    category: Categories.personalInformation,
    title: "Car Insurance and Registration",
    issued_on: new Date(2020, 1, 1),
    expire_on: new Date(2021, 1, 1),
    filename: "",
    url: "",
    requirement_met: true,
  },
  {
    category: Categories.legalDocumentation,
    title: "Agency Application Form",
    issued_on: new Date(2021, 1, 21),
    expire_on: new Date(2021, 10, 21),
    filename: "",
    url: "",
    requirement_met: false,
  },
  {
    category: Categories.firstAid,
    title: "Liability Insurance",
    issued_on: new Date(2021, 4, 10),
    expire_on: new Date(2023, 4, 10),
    filename: "",
    url: "",
    requirement_met: false,
  },
  {
    category: Categories.personalInformation,
    title: "Liability Insurance",
    issued_on: new Date(2021, 4, 10),
    expire_on: new Date(2023, 4, 10),
    filename: "",
    url: "",
    requirement_met: true,
  },
];

export const categories: Category[] = [
  {
    name: Categories.personalInformation,
    titles: [
      "Agency Application Form",
      "Resume",
      "Car Insurance and Registration",
      "Liability Insurance",
      "Yearly Physical Examinations",
      "Independent Contractor Agency Agreement",
      "Employment Verification (if applicable) I-9",
    ],
  },
  {
    name: Categories.legalDocumentation,
    titles: [
      "Copy of Driver’s License",
      "Copy of Social Security Card",
      "Legal Status: Copy of Residence or Work Permit / Copy of Passport",
      "Affidavit of Good Moral Character (5 yrs.)",
      "Authorization to obtain Background Screening/Background Screening Level 2 Results (5yrs.)",
      "Yearly Local Police Record",
    ],
  },
  {
    name: Categories.educationalLevel,
    titles: [
      "Copy of High School Diploma/ B.S. Degree/ M.S. Degree",
      "Copy of Professional License or Certification",
      "HHA/ CNA/ LMHC/ LCSW/ LFMT/ BCBA/ BCaBA/ RBT 40 hours / Behavior Assistant 20 hours",
    ],
  },
  {
    name: Categories.trainingCertificates,
    titles: [
      "DCCC (Direct Care Core Competency)",
      "Zero Tolerance ",
      "HIPAA (Health Insurance Portability and Accountability Act)",
      "HIV/AIDS",
    ],
  },
  {
    name: Categories.firstAid,
    titles: [
      "American Red Cross",
      "American Heart Association",
      "American Safety and Health Institute",
      "Vida Training",
    ],
  },
];
