import { useParams, Link, useHistory } from "react-router-dom";
import useStyles from "./styles";
import logo from "../../../core/assets/images/apd-desk.png";
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { useEffect } from "react";
import { useInjection } from "../../../../core/dependency_injection";
import UserActions from "../../../../application/user/actions";
import { useUserDispatch, useUserState } from "../../../../application/user";
import { useSnackbar } from "notistack";

type RouteParams = {
  id: string;
};

export default function Activate() {
  const classes = useStyles();

  const history = useHistory();

  const { activateMessage, isSubmiting, isLoading } = useUserState();
  const userDispatch = useUserDispatch();
  const userActions = useInjection<UserActions>("UserActions");

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams<RouteParams>();

  // console.log(`activateMessage`, activateMessage);
  // console.log(`isLoading Activate`, isLoading);

  // useMemo(() => function, input)

  useEffect(() => {
    if (!isLoading) {
      userActions.activateUser(userDispatch, id, history, enqueueSnackbar);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <div className={classes.root}>
      <Link to="/">
        <img src={logo} alt="APD Desk Logo" width="300px" />
      </Link>
      <Card className={classes.card}>
        {isSubmiting && <LinearProgress />}
        <CardContent>
          <Typography>{activateMessage}</Typography>
        </CardContent>
      </Card>
    </div>
  );
}
