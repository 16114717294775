import GetAccessTokenDatasource, {
  IGetTokenDatasource,
} from "../../core/datasource/get_token_datasource";

export interface IContractorLocalDatasource extends IGetTokenDatasource {
  // getAccessToken(): string | null;
}

export default class ContractorLocalDatasource
  extends GetAccessTokenDatasource
  implements IContractorLocalDatasource {}
