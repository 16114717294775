import React from "react";
import useStyles from "./styles";
import logo from "../core/assets/images/apd-desk.png";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  document.title = "APD Desk | Not Found";
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link to="/">
        <img src={logo} alt="APD Desk Logo" width="300px" />
      </Link>
      <h1>Error 404</h1>
      <p>Page not found</p>
    </div>
  );
}
