import {
  AUTH_ENDPOINT,
  CONTRACTORS_ENDPOINT,
  Headers,
} from "../../../core/tools/env";
import { CompanyDto } from "../../company/dtos/CompanyDto";
import { handleResponse } from "../../core/helpers";
import SignDto from "../dtos/SignDto";
import { UserDto } from "../dtos/UserDto";

export interface IUserRemoteDatasource {
  login(email: string, password: string): Promise<SignDto>;
  register(
    name: string,
    email: string,
    password: string,
    company: CompanyDto
  ): Promise<SignDto>;
  update(token: string, user: UserDto): Promise<void>;
  updateContractor(token: string, user: UserDto): Promise<void>;
  activateUser(token: string): Promise<void>;
  sendValidateEmail(email: string, name: string): Promise<void>;
  getAuthenticatedUser(token: string): Promise<UserDto>;
  sendResetPasswordEmail(email: string): Promise<void>;
  resetPassword(token: string, password: string): Promise<void>;
}

export default class UserRemoteDatasource implements IUserRemoteDatasource {
  async sendResetPasswordEmail(email: string): Promise<any> {
    const response = await fetch(AUTH_ENDPOINT + "/sendResetPassword", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
      }),
    });
    console.log(`response`, response);
    await handleResponse(response);
  }

  async resetPassword(token: string, password: string): Promise<any> {
    const response = await fetch(AUTH_ENDPOINT + "/resetPassword", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: token,
        password: password,
      }),
    });
    console.log(`response`, response);
    await handleResponse(response);
  }

  async sendValidateEmail(email: string, name: string): Promise<void> {
    const response = await fetch(AUTH_ENDPOINT + "/sendValidateEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        name: name,
      }),
    });
    console.log(`response`, response);
    await handleResponse(response);
  }
  async activateUser(token: string): Promise<void> {
    const response = await fetch(AUTH_ENDPOINT + "/activate", {
      method: "put",
      headers: Headers(),
      body: JSON.stringify({
        token: token,
      }),
    });
    console.log(`response`, response);
    await handleResponse(response);
  }
  async login(email: string, password: string): Promise<SignDto> {
    const response = await fetch(AUTH_ENDPOINT + "/login", {
      method: "post",
      headers: Headers(),
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    const data: SignDto = await response.json();
    if (!response.ok) throw data;
    return data;
  }

  async register(
    name: string,
    email: string,
    password: string,
    company: CompanyDto
  ): Promise<SignDto> {
    const response = await fetch(AUTH_ENDPOINT + "/register", {
      method: "post",
      headers: Headers(),
      body: JSON.stringify({
        email: email,
        password: password,
        name: name,
        company: company,
      }),
    });
    const data = await handleResponse(response);
    return data;
  }

  async update(token: string, user: UserDto): Promise<void> {
    const response = await fetch(AUTH_ENDPOINT + "/updateProfile", {
      method: "put",
      headers: Headers(token),
      body: JSON.stringify(user),
    });
    const data = await response.json();
    if (!response.ok) throw data;
    return data;
  }

  async updateContractor(token: string, user: UserDto): Promise<void> {
    const body = JSON.stringify(user);
    const response = await fetch(CONTRACTORS_ENDPOINT + "/update", {
      method: "put",
      headers: Headers(token),
      body: body,
    });
    await handleResponse(response);
  }

  async getAuthenticatedUser(token: string): Promise<UserDto> {
    const response = await fetch(AUTH_ENDPOINT + "/profile", {
      method: "get",
      headers: Headers(token),
    });
    const data = await handleResponse(response);
    return new UserDto(data.user);
  }
}
