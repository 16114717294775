import { Company } from "../../domain/company";
import { Result } from "../../domain/core/Result";
import { IUserRepository } from "../../domain/user/iuser.repository";
import { User } from "../../domain/user/models/User";
import { CompanyDto } from "../company/dtos/CompanyDto";
import { IUserLocalDatasource } from "./datasources/local";
import { IUserRemoteDatasource } from "./datasources/remote";
import SignDto from "./dtos/SignDto";
import { UserDto } from "./dtos/UserDto";

export default class UserRepository implements IUserRepository {
  private remoteDatasource: IUserRemoteDatasource;
  private localDatasource: IUserLocalDatasource;

  public constructor(
    remoteDatasource: IUserRemoteDatasource,
    localDatasource: IUserLocalDatasource
  ) {
    this.remoteDatasource = remoteDatasource;
    this.localDatasource = localDatasource;
  }

  async sendResetPasswordEmail(email: string): Promise<Result<void>> {
    try {
      await this.remoteDatasource.sendResetPasswordEmail(email);
      return Result.rigth("Email send successfully");
    } catch (error) {
      return Result.left(error.message);
    }
  }
  async resetPassword(token: string, password: string): Promise<Result<void>> {
    try {
      await this.remoteDatasource.resetPassword(token, password);
      return Result.rigth("Your password has been changed");
    } catch (error) {
      return Result.left(error.message);
    }
  }

  async sendValidateEmail(email: string, name: string): Promise<Result<void>> {
    try {
      const token = this.localDatasource.getAccessToken();
      if (!token) return Result.left("Unauthenticated");
      await this.remoteDatasource.sendValidateEmail(email, name);
      return Result.rigth("Email send successfully");
    } catch (error) {
      return Result.left(error.message);
    }
  }

  async activateUser(token: string): Promise<Result<void>> {
    try {
      await this.remoteDatasource.activateUser(token);
      return Result.rigth("User active");
    } catch (error) {
      return Result.left(error.message);
    }
  }

  signOut(): void {
    this.localDatasource.removeAccessToken();
  }

  async login(email: string, password: string): Promise<Result<User>> {
    try {
      const signDto: SignDto = await this.remoteDatasource.login(
        email,
        password
      );
      this.localDatasource.setAccessToken(signDto.access_token);
      return Result.rigth<User>(signDto.user);
    } catch (error) {
      return Result.left(error.message);
    }
  }

  async register(
    name: string,
    email: string,
    password: string,
    company: Company
  ): Promise<Result<User>> {
    try {
      const signDto = await this.remoteDatasource.register(
        name,
        email,
        password,
        CompanyDto.fromDomain(company)
      );
      this.localDatasource.setAccessToken(signDto.access_token);
      return Result.rigth<User>(signDto.user);
    } catch (error) {
      return Result.left(error.message);
    }
  }

  async update(user: User): Promise<Result<void>> {
    try {
      const userDto = UserDto.fromDomain(user);
      const token = this.localDatasource.getAccessToken();
      if (!token) return Result.left("Unauthenticated");
      if (user.contractorProps) {
        await this.remoteDatasource.updateContractor(token, userDto);
      } else {
        await this.remoteDatasource.update(token, userDto);
      }
      return Result.rigth("Update successfully");
    } catch (error) {
      return Result.left(error.message);
    }
  }

  async getAuthenticatedUser(): Promise<Result<User>> {
    try {
      const token = this.localDatasource.getAccessToken();
      console.log(`auth token`, token);
      if (!token) return Result.left("Unauthenticated");
      const userDto = await this.remoteDatasource.getAuthenticatedUser(token);
      return Result.rigth<User>(userDto.toDomain());
    } catch (error) {
      return Result.left(error.message);
    }
  }
}
