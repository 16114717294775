import { Company } from "../../company/models/Company";
import { Contractor } from "../../contractor/models/Contractor";

interface UserProps {
  id?: string;
  role?: string;
  name: string;
  email: string;
  password?: string;
  phone?: string;
  company?: Company;
  contractorProps?: Contractor;
  active?: boolean;
}

export class User {
  id?: string;
  role?: string;
  name: string;
  email: string;
  password?: string;
  phone?: string;
  company?: Company;
  contractorProps?: Contractor;
  active?: boolean;

  constructor(props: UserProps) {
    this.id = props.id;
    this.company = props.company;
    this.email = props.email;
    this.password = props.password;
    this.phone = props.phone;
    this.name = props.name;
    this.role = props.role;
    this.contractorProps = props.contractorProps;
    this.active = props.active;
  }
}
