import { Contractor } from "../../../domain/contractor/models/Contractor";
import { DocumentDto } from "../../document";

export interface IContractorProps {
  id?: string;
  address?: string;
  name?: string;
  providerId?: string;
  licenseNumber?: string;
  profession?: string;
  language?: string[];
  documents?: DocumentDto[];
}

export class ContractorDto {
  id?: string;
  address?: string;
  providerId?: string;
  licenseNumber?: string;
  profession?: string;
  language?: string[];
  documents?: DocumentDto[];

  constructor(props: IContractorProps) {
    this.id = props.id;
    this.address = props.address;
    this.providerId = props.providerId;
    this.licenseNumber = props.licenseNumber;
    this.profession = props.profession;
    this.language = props.language;
    this.documents = props.documents?.map(
      (item) =>
        new DocumentDto({
          ...item,
        })
    );
  }

  public static fromDomain(contractor: Contractor): ContractorDto {
    return new ContractorDto({
      id: contractor.id,
      address: contractor.address,
      providerId: contractor.providerId,
      licenseNumber: contractor.licenseNumber,
      profession: contractor.profession,
      language: contractor.language,
      documents: contractor.documents?.map((item) =>
        DocumentDto.fromDomain(item)
      ),
    });
  }

  public toDomain(): Contractor {
    return new Contractor({
      id: this.id,
      address: this.address,
      providerId: this.providerId,
      licenseNumber: this.licenseNumber,
      profession: this.profession,
      language: this.language,
      documents: this.documents?.map((item) => item.toDomain()),
    });
  }
}
