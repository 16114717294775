import React from "react";
import { Settings as SettingsIcon } from "@material-ui/icons";
import PanelHeader from "../../components/PanelHeader";

export default function Settings() {
  document.title = "APD Desk | Settings";

  return (
    <div>
      <PanelHeader icon={<SettingsIcon />} title="Settings" />
    </div>
  );
}
