import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  useContractorDispatch,
  useContractorState,
} from "../../../../../../application/contractor";
import { PanelCircularProgress } from "../../../../../core/components/Progress";
import { Add, Delete, Edit, People } from "@material-ui/icons";
import EditContractorModalContent from "../../components/EditContractorModalContent";
import useOpenable from "../../../../../core/hooks/useOpenable";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { User } from "../../../../../../domain/user/models/User";
import { useInjection } from "../../../../../../core/dependency_injection/ioc.react";
import ContractorActions from "../../../../../../application/contractor/actions";
import PanelHeader from "../../../../components/PanelHeader";
import WrappedModal from "../../../../../core/components/Modal";
import ContractorProfileCard from "../../../../components/ContractorProfileCard";
import EditDocumentContent from "../../components/EditDocumentContent";

type RouteParams = {
  id: string;
};

export default function ContractorDetails(props: any) {
  // let { path, url } = useRouteMatch();
  const { id } = useParams<RouteParams>();
  const history = useHistory();

  const { selectedContractor, contractors, isSubmiting } = useContractorState();
  const contractorDispatch = useContractorDispatch();

  const contractorActions =
    useInjection<ContractorActions>("ContractorActions");

  const [open, handleOpen, handleClose] = useOpenable();
  const [openDialog, handleOpenDialog, handleCloseDialog] = useOpenable();
  const [openEditDocument, handleOpenEditDocument, handleCloseEditDocument] =
    useOpenable();

  const { enqueueSnackbar } = useSnackbar();

  // console.log(`selectedContractor`, selectedContractor);

  useEffect(() => {
    if (contractors.length !== 0) {
      const contractor = contractors.find((item) => item.id === id);
      contractorActions.setSelectedContractor(contractorDispatch, contractor);
    }
  }, [contractors, contractorDispatch, id, contractorActions]);

  document.title = `APD Desk | Contractors ${
    selectedContractor?.name ? "| " + selectedContractor?.name : ""
  }`;

  console.log(`selectedContractor`, selectedContractor);

  const panelActions = [
    {
      label: "Add Document",
      icon: Add,
      color: "success",
      onClick: () => {
        handleOpenEditDocument();
      },
    },
    {
      label: "Edit",
      icon: Edit,
      onClick: () => {
        handleOpen();
      },
    },
    {
      label: "Delete",
      color: "danger",
      icon: Delete,
      onClick: () => {
        handleOpenDialog();
      },
    },
  ];

  const breadcrumbsRoutes = [
    {
      label: "Contractors",
      icon: People,
      to: "/app/contractors",
    },
    {
      label: selectedContractor?.name,
      // icon: People,
    },
  ];

  return !isSubmiting ? (
    <>
      <PanelHeader
        breadcrumbsRoutes={breadcrumbsRoutes}
        actions={panelActions}
      />
      <ContractorProfileCard selectedContractor={selectedContractor} />
      <WrappedModal open={open} handleClose={handleClose}>
        <EditContractorModalContent
          isEditing
          onSuccessCallback={(contractor: User) => {
            // console.log(`contractor`, contractor);
            contractorActions.updateContractor(
              contractorDispatch,
              contractor,
              enqueueSnackbar,
              handleClose
            );
          }}
        />
      </WrappedModal>
      <WrappedModal
        open={openEditDocument}
        handleClose={handleCloseEditDocument}
      >
        <EditDocumentContent
          onSuccessCallback={(data: FormData) => {
            data.forEach((item) => console.log(`item`, item));
            contractorActions.uploadDocument(
              contractorDispatch,
              data,
              enqueueSnackbar,
              handleCloseEditDocument
            );
          }}
        />
      </WrappedModal>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this contractor?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure do you want to delete this contractor.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              handleCloseDialog();
              contractorActions.deleteContractor(
                contractorDispatch,
                selectedContractor?.id ?? "",
                enqueueSnackbar,
                history
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <PanelCircularProgress />
  );
}
