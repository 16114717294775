import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import App from "./App";
import { MainCircularProgress } from "./core/components/Progress";
import { useUserDispatch, useUserState } from "../application/user";
import ErrorPage from "./Error";
import HomePage from "./Home";
import { PrivateRoute, PublicRoute } from "./core/components/Routes";
import Auth from "./Auth";
import { useInjection } from "../core/dependency_injection";
import UserActions from "../application/user/actions";
import { useEffect } from "react";

function Root() {
  const { isLoading } = useUserState();
  const userDispatch = useUserDispatch();

  const userActions = useInjection<UserActions>("UserActions");

  useEffect(() => {
    // TODO: Check if the user is authenticated and if it's fetch his data form backend
    // checkAuthenticated(userDispatch);
    userActions.checkAuthenticated(userDispatch);
    // eslint-disable-next-line
  }, []);

  // console.log(`user`, user);
  // console.log(`isLoading Root`, isLoading);

  return !isLoading ? (
    <Router>
      <Switch>
        {/* <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />*/}
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PublicRoute exact path="/" component={HomePage} />
        <PrivateRoute path="/app" component={App} />
        <Route path="/auth" component={Auth} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Router>
  ) : (
    <MainCircularProgress />
  );
}

export default Root;
