import React from "react";
import {
  TableCell,
  TableRow,
  withStyles,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
// import PropTypes from "prop-types";
import { hexToRgb } from "../../../../../core/tools/helpers";
import useStyles from "./styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)}, 1)`,
    color: theme.palette.common.white,
  },
  // body: {
  //   fontSize: 14,
  // },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
  hover: {
    cursor: "pointer",
  },
}))(TableRow);

const EnhancedTable = (props: any) => {
  const { dense, columns, data, onClickCallback } = props;

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.table}>
      <TableContainer className={classes.tableContainer}>
        <Table
          size={dense ? "small" : "medium"}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {columns.map((column: any, index: any) => (
                <StyledTableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth ?? 170 }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: any) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onClick={() => {
                      onClickCallback(row);
                    }}
                  >
                    {columns.map((column: any, index: any) => {
                      const value = column.property
                        ? row[column.id][column.property]
                        : row[column.id];
                      return (
                        <TableCell key={index} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

EnhancedTable.defaultProps = {
  dense: false,
};

export default EnhancedTable;
