import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0px 20px 0px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    marginRight: "5px",
    color: theme.palette.primary.main,
    // margin: `3px 10px 0px ${theme.spacing(0.5)}px`,
    // width: 30,
    // height: 30,
  },
  link: {
    display: "flex",
    fontSize: "24px",
    textDecoration: "none",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  text: {
    color: theme.palette.text.primary,
    display: "flex",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
}));
