const LOCAL_HOST = "http://localhost:5000";
const REMOTE_HOST = "https://api.apddesk.com";

export const LOCAL_STORAGE_TOKEN_KEY = "apdesk_access_token";

// export const CRIPTR_SECRET_KEY = "cRIptR$s3CrET*@";

const HOST = process.env.NODE_ENV === "development" ? LOCAL_HOST : REMOTE_HOST;
// const HOST = REMOTE_HOST;

export const AUTH_ENDPOINT = HOST + "/auth";
export const CONTRACTORS_ENDPOINT = HOST + "/contractor";

// export const Headers = { "Content-Type": "application/json" };
export const Headers = (token?: string) => {
  const headers = { "Content-Type": "application/json" };
  return token ? { ...headers, Authorization: `Bearer ${token}` } : headers;
};
