import "reflect-metadata";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import ReactDOM from "react-dom";
import Root from "./presentation/Root";
import reportWebVitals from "./reportWebVitals";
import Themes from "./presentation/core/themes";
import ContextProvider from "./application";
import { SnackbarProvider } from "notistack";
import {
  DependencyProvider,
  registerDependencies,
} from "./core/dependency_injection";
import { container } from "tsyringe";

registerDependencies();

ReactDOM.render(
  <ContextProvider>
    <DependencyProvider container={container}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // TransitionComponent={Fade}
      >
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <Root />
        </ThemeProvider>
      </SnackbarProvider>
    </DependencyProvider>
  </ContextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
