import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import useStyles from "./styles";

export default function PreviewDocument(props: any) {
  const classes = useStyles();
  const { url } = props;

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const onNext = () => {
    setPageNumber(pageNumber + 1);
  };

  const onPrevious = () => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <div className={classes.root}>
      <div className={classes.pages}>
        Page {pageNumber} of {numPages}
      </div>
      <Document
        className={classes.document}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div className={classes.buttons}>
        <button disabled={pageNumber === 1} onClick={onPrevious}>
          previous
        </button>
        <button disabled={pageNumber === numPages} onClick={onNext}>
          next
        </button>
      </div>
    </div>
  );
}
