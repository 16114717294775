import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  },
  card: {
    marginTop: "30px",
    width: "25vw",
    minHeight: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
    // overflow: "visible",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  margin: {
    marginTop: "20px",
  },
  button: {
    marginTop: "30px",
  },
}));
