import { makeStyles } from "@material-ui/core";
import { DocumentTableRowsColors } from "../../../../../../../../core/tools/constants";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  item: {
    padding: "15px",
  },
  met: {
    backgroundColor: DocumentTableRowsColors.met,
  },
  expire_soon: {
    backgroundColor: DocumentTableRowsColors.expire_soon,
  },
  expired: {
    backgroundColor: DocumentTableRowsColors.expired,
  },
}));
