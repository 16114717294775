import { CircularProgress } from "@material-ui/core";
import useStyles from "./styles";

const MainCircularProgress = () => {
  const classes = useStyles();
  return (
    <div className={classes.progressContainer}>
      {/* <img src={Logo} alt="logo" width="100px" /> */}
      <CircularProgress style={{ marginTop: "10px" }} />
    </div>
  );
};

function PanelCircularProgress() {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );
}

export { MainCircularProgress, PanelCircularProgress };
