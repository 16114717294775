import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  avatarContainer: {
    position: "relative",
    top: -10,
    zIndex: 3,
    width: "120px",
    height: "120px",
    backgroundColor: "grey",
    borderRadius: "60px",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
    },
  },
  image: {
    borderRadius: "60px",
  },
  infoContainer: {
    display: "flex",
    width: "40vw",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "50vw",
    },
  },
}));
