import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUserState } from "../../../../application/user";

type RouteProps = {
  component: React.FunctionComponent;
  path: string;
  exact?: boolean;
};

export function PublicRoute({ component, path, exact }: RouteProps) {
  const { isAuthenticated } = useUserState();

  return (
    <Route
      exact={exact ?? false}
      path={path}
      render={(props: any) => {
        // console.log(`PublicRoute props`, props);
        return isAuthenticated ? (
          <Redirect
            to={{
              pathname:
                props.location?.state?.from?.pathname ?? "/app/dashboard",
            }}
          />
        ) : (
          React.createElement(component, props)
        );
      }}
    />
  );
}

export function PrivateRoute({ component, path, exact }: RouteProps) {
  const { isAuthenticated } = useUserState();

  return (
    <Route
      exact={exact ?? false}
      path={path}
      render={(props: any) => {
        // console.log(`PrivateRoute props`, props);
        return isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
}
