import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "70vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
