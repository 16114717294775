import React from "react";
import { Add, People } from "@material-ui/icons";
import {
  // addContractor,
  // setSelectedContractor,
  useContractorDispatch,
  useContractorState,
} from "../../../../../../application/contractor";
import { PanelCircularProgress } from "../../../../../core/components/Progress";
import { useHistory } from "react-router-dom";
import useOpenable from "../../../../../core/hooks/useOpenable";
import WrappedModal from "../../../../../core/components/Modal";
import EditContractorModalContent from "../../components/EditContractorModalContent";
import { useSnackbar } from "notistack";
import { User } from "../../../../../../domain/user/models/User";
import { useInjection } from "../../../../../../core/dependency_injection/ioc.react";
import ContractorActions from "../../../../../../application/contractor/actions";
import PanelHeader from "../../../../components/PanelHeader";
import { EnhancedTable } from "../../../../../core/components/Table";

export default function ContractorsPage() {
  document.title = "APD Desk | Contractors";

  const history = useHistory();

  const { contractors, isFetching } = useContractorState();
  const contractorDispatch = useContractorDispatch();

  const contractorActions =
    useInjection<ContractorActions>("ContractorActions");

  const [open, handleOpen, handleClose] = useOpenable();

  const { enqueueSnackbar } = useSnackbar();

  // console.log(`contractors`, contractors);

  const columns = [
    { id: "name", label: "Name", minWidth: 170, align: "left" },
    { id: "email", label: "Email", minWidth: 100, align: "center" },
    {
      id: "phone",
      label: "Phone",
      minWidth: 170,
      align: "center",
      // format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "contractorProps",
      property: "address",
      label: "Address",
      minWidth: 170,
      align: "center",
      // format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "contractorProps",
      property: "providerId",
      label: "Provider ID",
      minWidth: 170,
      align: "center",
      // format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "contractorProps",
      property: "licenseNumber",
      label: "License Number",
      minWidth: 170,
      align: "center",
      // format: (value) => value.toFixed(2),
    },
    {
      id: "contractorProps",
      property: "language",
      label: "Languages",
      minWidth: 170,
      align: "center",
      format: (value: any) => {
        let string = "";
        value.forEach((item: any) => {
          string += item + ", ";
        });
        return string.substr(0, string.length - 2);
      },
    },
  ];

  const panelActions = [
    {
      label: "Add",
      icon: Add,
      onClick: () => {
        handleOpen();
      },
    },
  ];

  return !isFetching ? (
    <>
      <PanelHeader
        title="Contractors"
        icon={<People />}
        actions={panelActions}
      />
      {contractors.length === 0 ? (
        <div>No contractors registered</div>
      ) : (
        <EnhancedTable
          data={contractors}
          columns={columns}
          onClickCallback={(contractor: any) => {
            // console.log(`onClick item:`, contractor);
            contractorActions.setSelectedContractor(
              contractorDispatch,
              contractor
            );
            history.push(`/app/contractors/${contractor.id}`);
          }}
        />
      )}
      <WrappedModal open={open} handleClose={handleClose}>
        <EditContractorModalContent
          onSuccessCallback={(contractor: User) => {
            // console.log(`contractor`, contractor);
            contractorActions.addContractor(
              contractorDispatch,
              contractor,
              enqueueSnackbar,
              handleClose
            );
          }}
        />
      </WrappedModal>
    </>
  ) : (
    <PanelCircularProgress />
  );
}
