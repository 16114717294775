import React from "react";
import { User } from "../../domain/user/models/User";

export const SET_LOADING = "SET_LOADING";
export const SET_SUBMITING = "SET_SUBMITING";
export const SET_USER = "SET_USER";
export const SET_ACTIVATE_MESSAGE = "SET_ACTIVATE_MESSAGE";
export const SET_ACTIVE = "SET_ACTIVE";
export const SIGN_FAILURE = "SIGN_FAILURE";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";

export const initialValues: UserState = {
  user: { name: "", email: "" },
  isAuthenticated: false,
  isLoading: true,
  isSubmiting: false,
  activateMessage: "",
};

export type UserDispatch = React.Dispatch<UserAction>;

export type UserState = {
  isAuthenticated: boolean;
  isLoading: boolean;
  isSubmiting: boolean;
  activateMessage: string;
  user: User;
};

export type UserAction =
  | {
      type: typeof SET_LOADING;
      payload: boolean;
    }
  | {
      type: typeof SET_SUBMITING;
      payload: boolean;
    }
  | {
      type: typeof SIGN_FAILURE;
    }
  | {
      type: typeof SIGN_OUT_SUCCESS;
    }
  | {
      type: typeof SET_USER;
      payload: User;
    }
  | {
      type: typeof SET_ACTIVE;
      payload: boolean;
    }
  | {
      type: typeof SET_ACTIVATE_MESSAGE;
      payload: string;
    }
  | {
      type: typeof UPDATE_USER;
      payload: User;
    };
