import { useState } from "react";

export default function useOpenable(): [boolean, () => void, () => void] {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return [open, handleOpen, handleClose];
}
