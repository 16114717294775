import { DocumentTableRowsColors, OWNER_ROLE } from "./constants";
import {
  contractorRoutes,
  ownerRoutes,
} from "../../presentation/App/core/routes";
import { Document } from "../../domain/document";

export const hexToRgb = (input: string) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

export const languagesFormater = (value: any[]) => {
  // console.log(`languagesFormater value`, value);
  let string = "";
  value.forEach((item) => {
    string += item + ", ";
  });
  return string.substr(0, string.length - 2);
};

export const getRoutes = (role: any) => {
  return role === OWNER_ROLE ? ownerRoutes : contractorRoutes;
};

export const getDaysBetween = (start: Date, end: Date) => {
  return Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
};

export const getRowColor = (doc: Document) => {
  const daysBetween = getDaysBetween(new Date(), new Date(doc.expire_on));
  const monthBetween = Math.round(daysBetween / 30);
  var color: string = doc.requirement_met
    ? DocumentTableRowsColors.met
    : DocumentTableRowsColors.not_met;
  if (monthBetween <= 6) color = DocumentTableRowsColors.expire_soon;
  if (monthBetween < 0) color = DocumentTableRowsColors.expired;
  return color;
};
