import { UserProvider } from "./user";
import { ContractorProvider } from "./contractor";

export default function ContextProvider({ children }: any) {
  return (
    <UserProvider>
      <ContractorProvider>{children}</ContractorProvider>
    </UserProvider>
  );
}
