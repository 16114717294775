import { Typography } from "@material-ui/core";
import React from "react";
import { User } from "../../../../../../domain/user/models/User";
import profile from "../../../../../../presentation/core/assets/images/profile.png";
import ProfileAvatar from "../../../ProfileAvatar";
import useStyles from "./styles";

type ContractorHighlighsProps = {
  selectedContractor: User;
};

export default function ContractorHighlighs(props: ContractorHighlighsProps) {
  const classes = useStyles();
  const { selectedContractor } = props;

  // console.log(`selectedContractor`, selectedContractor);

  return (
    <div className={classes.root}>
      <ProfileAvatar imageSrc={profile} />
      <div className={classes.infoContainer}>
        <Typography>
          {selectedContractor?.contractorProps?.profession}
        </Typography>
        <Typography>{selectedContractor?.phone}</Typography>
        <Typography>{selectedContractor?.email}</Typography>
      </div>
    </div>
  );
}
