import React, { useRef } from "react";
import useStyles from "./styles";

export default function ProfileAvatar(props: any) {
  const { imageSrc } = props;

  const classes = useStyles();

  const inputFile: React.MutableRefObject<any> = useRef(null);

  const onImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let file = e.target.files[0];
      console.log(`file`, file);
      let data = new FormData();
      data.append("file", file);
    }
  };

  return (
    <div className={classes.avatarContainer}>
      <img
        className={classes.image}
        src={imageSrc}
        alt="profile"
        width="100%"
      />
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={onImageSelected}
      />
      <div
        className={classes.edit}
        onClick={() => {
          if (inputFile) {
            inputFile?.current?.click();
          }
        }}
      >
        Edit
      </div>
    </div>
  );
}
