import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function HomePage() {
  document.title = "APD Desk";

  // const { user } = useUserState();

  // console.log(`user`, user);

  // const userDispatch = useUserDispatch();

  // const userActions = useInjection<UserActions>("UserActions");

  // useEffect(() => {
  //   // TODO: Check if the user is authenticated and if it's fetch his data form backend
  //   // checkAuthenticated(userDispatch);
  //   userActions.checkAuthenticated(userDispatch);
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography>Home Page</Typography>
      <Link to="/auth/signIn">Sign In</Link>
      <Link to="/auth/signUp">Sign Up</Link>
    </div>
  );
}
