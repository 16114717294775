import { Document } from "../../domain/document";
import { User } from "../../domain/user/models/User";

export const SET_SELECTED_CONTRACTOR = "SET_SELECTED_CONTRACTOR";
export const SET_FETCHING = "SET_FETCHING";
export const SET_SUBMITING = "SET_SUBMITING";
export const SET_CONTRACTORS = "SET_CONTRACTORS";
export const ADD_CONTRACTOR = "ADD_CONTRACTORS";
export const UPDATE_CONTRACTOR = "UPDATE_CONTRACTOR";
export const UPDATE_CONTRACTOR_DOCUMENTS = "UPDATE_CONTRACTOR_DOCUMENTS";
export const DELETE_CONTRACTOR = "DELETE_CONTRACTOR";

export type ContractorAction =
  | {
      type: typeof SET_FETCHING;
      payload: boolean;
    }
  | {
      type: typeof SET_SUBMITING;
      payload: boolean;
    }
  | {
      type: typeof ADD_CONTRACTOR;
      payload: User;
    }
  | {
      type: typeof UPDATE_CONTRACTOR;
      payload: User;
    }
  | {
      type: typeof SET_SELECTED_CONTRACTOR;
      payload: User;
    }
  | {
      type: typeof SET_CONTRACTORS;
      payload: User[];
    }
  | {
      type: typeof UPDATE_CONTRACTOR_DOCUMENTS;
      payload: Document[];
    }
  | {
      type: typeof DELETE_CONTRACTOR;
      payload: string;
    };

export type ContractorDispatch = React.Dispatch<ContractorAction>;

export type ContractorState = {
  selectedContractor: User;
  contractors: User[];
  isFetching: boolean;
  isSubmiting: boolean;
};

export const initialValues: ContractorState = {
  selectedContractor: { name: "", email: "" },
  contractors: [],
  isFetching: false,
  isSubmiting: false,
};
